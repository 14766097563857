import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  popover: {
    zIndex: 11,
  },
  paper: {
    width: 224,
    height: 'auto',
    zIndex: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 19,
    borderRadius: 13,
    padding: 0,
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundLight,
  },
  iconBtn: {
    padding: 0,
    borderRadius: 'unset',
  },
  iconSm: {
    width: 22,
    height: 22,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  iconMd: {
    width: 32,
    height: 32,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  activeIcon: {
    color: () => theme.palette.colors.primaryColor,
  },
  brightnessOverlay: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 20,
    opacity: 0,
    position: 'fixed',
    background: '#000',
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
  },
}));

export default useStyles;

import firebase from 'utils/auth/firebase';
import * as RealmOperations from './operations';

const removeFavorite = async ({ collection, trackId }) => {
  const userId = await firebase?.auth()?.currentUser?.uid;

  return RealmOperations.removeOne({
    collection,
    query: {
      _user_id: userId,
      track_id: trackId,
    },
  });
};

export default removeFavorite;

import { useEffect, useCallback, useState, useRef } from 'react';
import logger from 'utils/logger';
import { getMediaKey } from 'api/media';
import HlsPlayer from './helpers/hls-player';

const usePlayer = (props) => {
  const {
    volume,
    position,
    isPlaying,
    activeTrack,
    playbackRate,
    resetPlayerAction,
    pauseTrackAction,
    isEbookReaderOpen,
    initialDuration,
    initialPosition,
    playNextTrackInQueueAction,
  } = props;

  const playerRef = useRef();
  const hlsPlayerRef = useRef();
  const prevActiveTrackRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState(initialDuration);
  const [currentTime, setCurrentTime] = useState(initialPosition);
  const [isRendered, setIsRendered] = useState(false);
  const [isFirstMount, setIsFirstMount] = useState(true);

  const setPositionOnFirstLoad = useCallback(() => {
    const firstCurrentTime = playerRef.current.currentTime;

    if (isFirstMount && firstCurrentTime !== position) {
      playerRef.current.currentTime = position;
      setIsFirstMount(false);
    }
  }, [position, isFirstMount]);

  const handleCurrentTimeUpdate = useCallback((event) => {
    setCurrentTime(event.target.currentTime);
  }, []);

  const handleDurationChange = useCallback((event) => {
    setDuration(event.target.duration);
  }, []);

  const handleEnded = useCallback(() => {
    playNextTrackInQueueAction();
  }, [playNextTrackInQueueAction]);

  const handleCanPlay = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleLoadedData = useCallback(() => {
    setIsLoading(false);
  }, []);

  const handleWaiting = useCallback(() => {
    setIsLoading(true);
  }, []);

  const handleLoadStart = useCallback(() => {
    setIsLoading(true);
  }, []);

  const handleAssetLoaded = () => {
    if (isEbookReaderOpen) {
      pauseTrackAction();
      playerRef.current.pause();
    } else {
      if (activeTrack?.progress) {
        playerRef.current.currentTime = activeTrack?.progress;
      }
      if (isPlaying) {
        playerRef.current.play().catch(logger.empty);
      } else {
        setPositionOnFirstLoad();
      }
    }
  };

  // Toggle play/pause
  useEffect(() => {
    if (isRendered && (!prevActiveTrackRef.current || prevActiveTrackRef.current === activeTrack)) {
      if (isPlaying) {
        playerRef.current.play().catch(logger.empty);
      } else {
        playerRef.current.pause();
      }
    }
  }, [isPlaying, isRendered]);

  useEffect(() => {
    if (isRendered && isEbookReaderOpen) {
      pauseTrackAction();
      playerRef.current.pause();
    }
  }, [isRendered, isEbookReaderOpen]);

  useEffect(() => {
    if (isRendered) {
      setCurrentTime(0);
      setDuration(activeTrack?.duration / 1000);

      if (activeTrack?.downloadUrl) {
        getMediaKey(activeTrack.downloadUrl)
          .then(({ key }) => {
            if (key) {
              hlsPlayerRef.current
                .loadUrl(key)
                .then(handleAssetLoaded)
                .catch(() => {
                  resetPlayerAction();
                });
            }
          })
          .catch(() => {
            resetPlayerAction();
          });
      } else {
        pauseTrackAction();
      }
      prevActiveTrackRef.current = activeTrack;
    }
  }, [activeTrack, isRendered]);

  // Change playback rate
  useEffect(() => {
    if (isRendered) {
      playerRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate, isRendered]);

  // Change volume
  useEffect(() => {
    if (isRendered) {
      playerRef.current.volume = volume;
    }
  }, [volume, isRendered]);

  // Init Player
  useEffect(() => {
    if (isRendered) {
      const playerInstance = playerRef.current;

      // Link HlsPlayer with player element
      hlsPlayerRef.current = new HlsPlayer(playerInstance);

      playerInstance.addEventListener('ended', handleEnded);
      playerInstance.addEventListener('canplay', handleCanPlay);
      playerInstance.addEventListener('waiting', handleWaiting);
      playerInstance.addEventListener('loadstart', handleLoadStart);
      playerInstance.addEventListener('loadeddata', handleLoadedData);
      playerInstance.addEventListener('timeupdate', handleCurrentTimeUpdate);
      playerInstance.addEventListener('durationchange', handleDurationChange);

      return () => {
        playerInstance.removeEventListener('ended', handleEnded);
        playerInstance.removeEventListener('canplay', handleCanPlay);
        playerInstance.removeEventListener('waiting', handleWaiting);
        playerInstance.removeEventListener('loadstart', handleLoadStart);
        playerInstance.removeEventListener('loadeddata', handleLoadedData);
        playerInstance.removeEventListener('timeupdate', handleCurrentTimeUpdate);
        playerInstance.removeEventListener('durationchange', handleDurationChange);
      };
    }

    setIsRendered(true);

    return () => {};
  }, [isRendered]);

  return [playerRef, { duration, currentTime, isLoading }];
};

export default usePlayer;

import makeStyles from '@material-ui/core/styles/makeStyles';
import { CONTENT_WIDTH } from 'utils/themes/sizes';

const useStyles = makeStyles((theme) => ({
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 11,
    position: 'fixed',
    paddingBottom: 'env(safe-area-inset-bottom)',
    backgroundColor: ({ activeTheme, isPDFReader, isEpubReader, isPubhubReader }) => {
      if (isPubhubReader || isEpubReader) {
        return theme.reader[activeTheme]?.mainColors?.backgroundDark;
      }
      if (isPDFReader) {
        return theme.palette.colors.backdropColor;
      }

      return theme.palette.colors.backdropColor;
    },
  },
  readerViewContentContainer: {
    position: 'relative',
    maxWidth: CONTENT_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    margin: '0 auto',
  },
}));

export default useStyles;

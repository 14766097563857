import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePlayer from 'hook/use-player';
import useMediaQuery from 'hook/use-media-query';
import useSuspendPinchZoom from 'hook/use-suspend-pinch-zoom';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';
import Portal from '@material-ui/core/Portal';
import Ping from 'components/ping';
import Timeline from 'components/player-components/timeline';
import FullScreenHeader from 'components/full-screen-components/header';
import MiniPlayer from 'components/mini-player';
import QueuePersistence from 'components/queue-persistence';
import HistoryPersistence from 'components/history-persistence';
import Navigation from 'components/player-components/navigation';
import CardMedia from '@material-ui/core/CardMedia';
import Gallery from './components/gallery';
import ControlPanel from './components/control-panel';
import useStyles from './audio-player.styles';
import TrackTitle from '../full-screen-components/track-title';

const AudioPlayer = memo((props) => {
  const isMobileFullPlayer = useMediaQuery('mobileFullPlayer');
  const {
    queue,
    volume,
    position,
    isPlaying,
    activeIndex,
    playbackRate,
    pauseTrackAction,
    resetPlayerAction,
    isEbookReaderOpen,
    isFullAudioPlayerOpened,
    playNextTrackInQueueAction,
    changeFullAudioPlayerViewAction,
  } = props;

  const playerContainerRef = useSuspendPinchZoom({ trigger: isFullAudioPlayerOpened });

  const activeTrack = queue?.[activeIndex] ?? {};

  const classes = useStyles({ backgroundImage: activeTrack?.image });

  const [playerRef, { duration, currentTime }] = usePlayer({
    volume,
    position,
    isPlaying,
    activeTrack,
    playbackRate,
    pauseTrackAction,
    resetPlayerAction,
    isEbookReaderOpen,
    playNextTrackInQueueAction,
    initialPosition: position || 0,
    initialDuration: activeTrack?.duration / 1000 || 0,
  });

  useEffect(() => {
    document.body.style.overflowY = isFullAudioPlayerOpened ? 'hidden' : 'unset';
  }, [isFullAudioPlayerOpened]);

  const closeFullPlayerView = useCallback(() => {
    changeFullAudioPlayerViewAction(false);
  }, []);

  const renderControlPanel = useCallback(() => <ControlPanel playerRef={playerRef} />, []);

  return (
    <>
      <audio ref={playerRef} preload="metadata" autoPlay={false} />
      <Portal container={document.getElementById('root')}>
        <Zoom mountOnEnter unmountOnExit in={isFullAudioPlayerOpened} timeout={470}>
          <Box ref={playerContainerRef} className={classes.playerContainer}>
            <CardMedia className={classes.backgroundImage} />
            <Box className={classes.bottomFadeEffect} />
            <Box className={classes.playerContent}>
              <FullScreenHeader
                height={104}
                item={activeTrack}
                onClose={closeFullPlayerView}
                renderControlPanel={renderControlPanel}
              />
              <Gallery />
              {isMobileFullPlayer && (
                <Box className={classes.trackTitleContainer}>
                  <TrackTitle item={activeTrack} />
                </Box>
              )}
              <Navigation
                playerRef={playerRef}
                className={classes.navigation}
                iconClassName={classes.navigationIcon}
                iconBtnClassName={classes.navigationIconBtn}
                playIconClassName={classes.navigationLargeIcon}
                pauseIconClassName={classes.navigationLargeIcon}
                forwardIconClassName={classes.navigationMediumIcon}
                skipLeftIconClassName={classes.navigationSmallIcon}
                skipRightIconClassName={classes.navigationSmallIcon}
                backwardIconClassName={classes.navigationMediumIcon}
              />
              <Box className={classes.timelineContainer}>
                <Timeline
                  duration={duration}
                  timePosition="bottom"
                  railClassName={classes.timelineRail}
                  elapsedTimeClassName={classes.timelineText}
                  totalTimeClassName={classes.timelineText}
                  currentTime={currentTime}
                  playerRef={playerRef}
                />
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Portal>
      <MiniPlayer playerRef={playerRef} duration={duration} currentTime={currentTime} />
      <QueuePersistence playerRef={playerRef} />
      <HistoryPersistence playerRef={playerRef} />
      <Ping playerRef={playerRef} duration={duration} />
    </>
  );
});

AudioPlayer.propTypes = {
  ...playerSharedPropTypes,
  isFullAudioPlayerOpened: PropTypes.bool.isRequired,
  changeFullAudioPlayerViewAction: PropTypes.func.isRequired,
};

export default AudioPlayer;

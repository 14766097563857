/* eslint-disable import/no-cycle */
import { createRoutine } from 'redux-saga-routines';
import { select, put, call, takeLatest } from 'redux-saga/effects';
import logger from 'utils/logger';
import getUserCollection from 'utils/realm/get-user-collection';
import addFavorite from 'utils/realm/add-favorite';
import removeFavorite from 'utils/realm/remove-favorite';
import { FAVORITES_PERSIST_KEY, getPersistentData, setPersistentData } from 'utils/localstorage';
import { getRealmDatabase, isRealmSupported } from '../realm';
import { getUserId } from '../auth';

const FAVORITES_COLLECTION_NAME = 'Favorite';

export const getFavoritesRoutine = createRoutine('GET_FAVOURITES');
export const addToFavoritesRoutine = createRoutine('ADD_TO_FAVOURITES');
export const removeFromFavoritesRoutine = createRoutine('REMOVE_FROM_FAVOURITES');

export const initialState = Object.freeze({
  favorites: [],
});

export const favoritesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getFavoritesRoutine.SUCCESS:
      return {
        ...state,
        favorites: payload,
      };
    case addToFavoritesRoutine.SUCCESS:
      return {
        ...state,
        favorites: [...state.favorites, payload],
      };
    case removeFromFavoritesRoutine.SUCCESS:
      return {
        ...state,
        favorites: payload,
      };
    default:
      return { ...state };
  }
};

export function* getFavoritesSaga({ database } = {}) {
  const userId = yield select(getUserId);
  const isRealmDBAvailable = yield select(isRealmSupported);

  try {
    let userFavoriteIds;

    if (isRealmDBAvailable) {
      const databaseInstance = database ?? (yield select(getRealmDatabase));
      const favoritesCollection = databaseInstance.collection(FAVORITES_COLLECTION_NAME);

      const userFavoriteItems = yield call(getUserCollection, favoritesCollection);
      userFavoriteIds = userFavoriteItems.map((item) => item?.track_id).filter(Boolean);

      logger.log('getFavoritesSaga', 'Loaded favorite items from Realm DB.', userFavoriteIds);
    } else {
      const allFavorites = getPersistentData(FAVORITES_PERSIST_KEY) ?? {};
      userFavoriteIds = allFavorites[userId] ?? [];

      logger.log('getFavoritesSaga', 'Loaded favorite items from localstorage.', userFavoriteIds);
    }

    yield put(getFavoritesRoutine.success(userFavoriteIds));

    return userFavoriteIds;
  } catch (error) {
    logger.error('getFavoritesSaga', 'Unable to get favorites', error);
  }

  return null;
}

export function* addToFavoritesSaga({ payload: trackId }) {
  const userId = yield select(getUserId);
  const isRealmDBAvailable = yield select(isRealmSupported);

  try {
    if (isRealmDBAvailable) {
      const database = yield select(getRealmDatabase);
      const favoritesCollection = database.collection(FAVORITES_COLLECTION_NAME);

      yield call(addFavorite, { collection: favoritesCollection, trackId });

      logger.log('addToFavoritesSaga', 'Added new item to Realm DB.', trackId);
    } else {
      const allFavorites = getPersistentData(FAVORITES_PERSIST_KEY) ?? {};

      const newFavorites = {
        ...allFavorites,
        [userId]: [...new Set([...(allFavorites?.[userId] ?? []), trackId])],
      };

      setPersistentData(FAVORITES_PERSIST_KEY, newFavorites);

      logger.log('addToFavoritesSaga', 'Added new item to localstorage.', trackId);
    }

    yield call(getFavoritesSaga);
  } catch (error) {
    logger.error('addToFavoritesSaga', 'Unable to add favorite item', error);
  }
}

export function* removeFromFavoritesSaga({ payload: trackId }) {
  const userId = yield select(getUserId);
  const isRealmDBAvailable = yield select(isRealmSupported);

  try {
    if (isRealmDBAvailable) {
      const database = yield select(getRealmDatabase);
      const favoritesCollection = database.collection(FAVORITES_COLLECTION_NAME);

      yield call(removeFavorite, { collection: favoritesCollection, trackId });

      logger.log('removeFromFavoritesSaga', 'Favorite item was removed from Realm DB.', trackId);
    } else {
      const allFavorites = getPersistentData(FAVORITES_PERSIST_KEY);

      const newFavorites = {
        ...allFavorites,
        [userId]: allFavorites[userId].filter((tId) => tId !== trackId),
      };

      setPersistentData(FAVORITES_PERSIST_KEY, newFavorites);

      logger.log('removeFromFavoritesSaga', 'Favorite item was removed from localstorage.', trackId);
    }

    yield call(getFavoritesSaga);
  } catch (error) {
    logger.error('getFavoritesSaga', 'Unable to remove favorite', error);
  }
}

export function* watchFavoritesSagas() {
  yield takeLatest(getFavoritesRoutine.REQUEST, getFavoritesSaga);
  yield takeLatest(addToFavoritesRoutine.REQUEST, addToFavoritesSaga);
  yield takeLatest(removeFromFavoritesRoutine.REQUEST, removeFromFavoritesSaga);
}

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icons from 'components/icons';
import { ebookDataPropType } from 'utils/prop-types';
import PopperOverlay from 'components/common/popper-overlay';
import ControlButton from 'components/common/control-button';
import Box from '@material-ui/core/Box';
import Portal from '@material-ui/core/Portal';
import { useTheme } from '@material-ui/core/styles';
import BrightnessPanel from './brightness-panel';
import useStyles from './settings-menu.styles';
import FontPanel from './font-panel';
import MarginPanel from './margin-panel';
import ThemePanel from './theme-panel';
import { MARGIN_VALUES } from './margin-panel/margin-panel.component';

const getClosest = (goal) =>
  Object.values(MARGIN_VALUES).reduce(
    (acc, curr) => (Math.abs(curr - goal) < Math.abs(acc - goal) ? curr : acc),
    MARGIN_VALUES.sm,
  );

const getBookStyles = (theme, activeTheme) => [
  {
    selector: ':not(a):not(hypothesis-highlight)',
    declarations: {
      color: theme.reader[activeTheme]?.mainColors?.foregroundLight,
      backgroundColor: theme.reader[activeTheme]?.mainColors?.backgroundLight,
    },
  },
  {
    selector: 'a',
    declarations: {
      color: theme.reader[activeTheme]?.mainColors?.backgroundLight,
      backgroundColor: theme.reader[activeTheme]?.mainColors?.foregroundLight,
    },
  },
];

const SettingsMenu = memo((props) => {
  const theme = useTheme();
  const {
    controlBtnProps,
    isEbookReaderOpen,
    ebookData: { currentPage, activeTheme },
  } = props;
  const classes = useStyles({ activeTheme });

  const settingsRef = useRef(null);
  const [brightness, setBrightness] = useState('100');
  const [margin, setMargin] = useState(MARGIN_VALUES.sm);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettings = useCallback(() => {
    setIsSettingsOpen((currentFlag) => !currentFlag);
  }, []);

  const closeSettingsMenu = useCallback((event) => {
    if (settingsRef.current && settingsRef.current.contains(event?.target)) {
      return;
    }

    setIsSettingsOpen((current) => {
      if (current) {
        event.stopImmediatePropagation();
      }
      return false;
    });
  }, []);

  useEffect(() => {
    const container = document.querySelector('#epub-reader-container');
    const containerWidth = container.offsetWidth;
    const settings = window?.READIUM?.reader?.viewerSettings?.();
    const currentGap = settings?.columnGap;
    const currentPercent = currentGap / containerWidth;
    const roundedPercent = Number(currentPercent.toFixed(1));
    const closest = getClosest(roundedPercent);

    setMargin(closest);
  }, []);

  useEffect(() => {
    const bookStyles = getBookStyles(theme, activeTheme);

    window?.READIUM?.reader?.setBookStyles(bookStyles);
    Object.entries(bookStyles[0].declarations).forEach(([style, value]) => {
      document.querySelector('#reading-area').style[style] = value;
    });
  }, [theme, activeTheme]);

  useEffect(() => {
    const readerContainer = document.querySelector('#epub-reader-container');

    readerContainer.addEventListener('click', closeSettingsMenu);
    window?.READIUM?.reader.addCustomIframeEventListener('click', closeSettingsMenu);

    return () => {
      readerContainer.removeEventListener('click', closeSettingsMenu);
      window?.READIUM?.reader.removeCustomIframeEventListener('click', closeSettingsMenu);
    };
  }, [currentPage]);

  return (
    <>
      <ControlButton
        buttonRef={settingsRef}
        onClick={toggleSettings}
        icon={Icons.ReaderSettings}
        {...controlBtnProps}
      />
      <PopperOverlay
        isOpen={isSettingsOpen}
        placement="bottom"
        onClose={closeSettingsMenu}
        anchor={settingsRef.current}
        popperClassName={classes.paper}
      >
        <BrightnessPanel brightness={brightness} setBrightness={setBrightness} />
        <FontPanel commonClasses={classes} />
        <MarginPanel margin={margin} setMargin={setMargin} commonClasses={classes} />
        <ThemePanel commonClasses={classes} />
      </PopperOverlay>
      {isEbookReaderOpen && (
        <Portal container={document.getElementById('root')}>
          <Box
            className={classes.brightnessOverlay}
            style={{ opacity: 1 - parseInt(`${brightness ?? '100'}`, 10) / 100 }}
          />
        </Portal>
      )}
    </>
  );
});

SettingsMenu.propTypes = {
  ebookData: ebookDataPropType,
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
  isEbookReaderOpen: PropTypes.bool.isRequired,
};

export default SettingsMenu;

import firebase from 'utils/auth/firebase';
import * as RealmOperations from './operations';

const getUserCollection = async (collection) => {
  const userId = await firebase?.auth()?.currentUser?.uid;

  return RealmOperations.findByUserId({
    collection,
    userId,
  });
};

export default getUserCollection;

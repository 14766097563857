import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PlayIcon = (props) => (
  <SvgIcon width="19" height="18" viewBox="0 0 19 18" {...props}>
    <g>
      <path
        d="M15.031 8.013L5.92 1.837c-.777-.527-1.828.047-1.828.993v12.337c0 .962 1.051 1.52 1.828.993l9.112-6.176c.686-.45.686-1.505 0-1.97z"
        transform="translate(-73.000000, -370.000000) translate(0.000000, -0.000000) translate(60.058594, 358.000000) translate(13.000000, 12.000000)"
      />
    </g>
  </SvgIcon>
);

export default PlayIcon;

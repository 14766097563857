import { useCallback, useRef } from 'react';

const useEpubReaderSwipes = ({ goToNextPage, goToPrevPage }) => {
  const touchEndRef = useRef(null);
  const touchStartRef = useRef(null);

  const handleTouchStart = useCallback((event) => {
    touchStartRef.current = event.targetTouches[0].clientX;
  }, []);

  const handleTouchMove = useCallback((event) => {
    touchEndRef.current = event.targetTouches[0].clientX;
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (touchEndRef.current !== null) {
      if (touchStartRef.current - touchEndRef.current > 100) {
        goToNextPage();
      }

      if (touchStartRef.current - touchEndRef.current < -100) {
        goToPrevPage();
      }
    }

    touchEndRef.current = null;
  }, []);

  const addSwipeHandlers = useCallback(() => {
    window?.READIUM?.reader.addCustomIframeEventListener('touchstart', handleTouchStart);
    window?.READIUM?.reader.addCustomIframeEventListener('touchmove', handleTouchMove);
    window?.READIUM?.reader.addCustomIframeEventListener('touchend', handleTouchEnd);

    const readerContainer = document.querySelector('#epub-reader-container');

    readerContainer.addEventListener('touchstart', handleTouchStart);
    readerContainer.addEventListener('touchmove', handleTouchMove);
    readerContainer.addEventListener('touchend', handleTouchEnd);
  }, []);

  const removeSwipeHandlers = useCallback(() => {
    window?.READIUM?.reader.removeCustomIframeEventListener('touchstart', handleTouchStart);
    window?.READIUM?.reader.removeCustomIframeEventListener('touchmove', handleTouchMove);
    window?.READIUM?.reader.removeCustomIframeEventListener('touchend', handleTouchEnd);

    const readerContainer = document.querySelector('#epub-reader-container');

    readerContainer.removeEventListener('touchstart', handleTouchStart);
    readerContainer.removeEventListener('touchmove', handleTouchMove);
    readerContainer.removeEventListener('touchend', handleTouchEnd);
  }, []);

  return [addSwipeHandlers, removeSwipeHandlers];
};

export default useEpubReaderSwipes;

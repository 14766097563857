import React, { memo } from 'react';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import VolumeOption from 'components/player-components/volume-option';
import QueueOption from 'components/player-components/queue-option';
import PlaybackRateOption from 'components/player-components/playback-rate-option';
import useStyles from './options.styles';

const Options = memo((props) => {
  const classes = useStyles();
  const { playerRef } = props;

  return (
    <>
      <Box className={classes.trackOptions}>
        <QueueOption placement="top-end" className={classes.optionButton} />
        <PlaybackRateOption placement="top-end" className={classes.optionButton} />
        <VolumeOption playerRef={playerRef} placement="top-end" className={classes.optionButton} />
      </Box>
    </>
  );
});

Options.propTypes = {
  playerRef: playerSharedPropTypes.playerRef,
};

export default Options;

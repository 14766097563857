import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/loader';
import useContentPermission from 'hook/use-content-permission';

const AccessSubscriptionGuard = ({ children, isSubscriptionLoaded }) => {
  const { hasSubscriptionFlow } = useContentPermission();

  return !hasSubscriptionFlow || isSubscriptionLoaded ? children : <Loader withOverlay />;
};

AccessSubscriptionGuard.propTypes = {
  isSubscriptionLoaded: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default AccessSubscriptionGuard;

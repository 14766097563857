import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons from 'components/icons';
import { FONT_SCALE_STEPS, FONT_SCALE_CHANGE_TYPE } from './font-panel.constants';
import useStyles from './font-panel.styles';

const FontPanel = memo(({ commonClasses }) => {
  const classes = useStyles();

  const [fontSize, setFontSize] = useState(100);

  const changeFontScale = useCallback(
    (scaleValue) => () => {
      const currentFontSize = window?.READIUM?.reader?.viewerSettings?.()?.fontSize;
      const nextValue = FONT_SCALE_STEPS[FONT_SCALE_STEPS.indexOf(currentFontSize ?? 100) + Number(scaleValue)];

      if (nextValue) {
        setFontSize(nextValue);
        window?.READIUM?.reader?.updateSettings?.({ fontSize: nextValue });
      }
    },
    [fontSize],
  );

  return (
    <Box className={classes.fontSizeContainer}>
      <IconButton onClick={changeFontScale(FONT_SCALE_CHANGE_TYPE.decrease)} className={commonClasses.iconBtn}>
        <Icons.ReaderFontDecrease className={commonClasses.iconSm} />
      </IconButton>
      <IconButton onClick={changeFontScale(FONT_SCALE_CHANGE_TYPE.increase)} className={commonClasses.iconBtn}>
        <Icons.ReaderFontIncrease className={commonClasses.iconSm} />
      </IconButton>
    </Box>
  );
});

FontPanel.propTypes = {
  commonClasses: PropTypes.shape({
    iconSm: PropTypes.string.isRequired,
    iconMd: PropTypes.string.isRequired,
    iconBtn: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
  }).isRequired,
};

export default FontPanel;

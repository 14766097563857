import { BSON } from 'realm-web';
import firebase from 'utils/auth/firebase';
import * as RealmOperations from './operations';

const addHistory = async ({
  collection,
  trackId,
  assetId,
  duration,
  progress,
  timestamp,
  isFinished,
  progressPercent,
}) => {
  const userId = await firebase?.auth()?.currentUser?.uid;

  return RealmOperations.updateOne({
    collection,
    query: {
      _user_id: userId,
      track_id: trackId,
      asset_id: assetId,
    },
    data: {
      progress,
      timestamp,
      _user_id: userId,
      track_id: trackId,
      asset_id: assetId,
      is_finished: isFinished,
      duration: new BSON.Double(duration),
      serialized_ebook_reader_position: '',
      progress_percent: new BSON.Double(progressPercent),
    },
  });
};

export default addHistory;

import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  controlPanelContainer: {
    display: 'flex',
    flexGrow: 1,
    marginRight: 28,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    '& >:not(:last-child)': {
      marginRight: 18,
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      marginTop: 28,
      marginRight: 20,
    },
  },
  controlButton: {
    width: 42,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.videoPlayer.colors.videoSurfaceColor,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: darken(theme.videoPlayer.colors.videoSurfaceColor, 0.1),
      },
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.videoPlayer.colors.videoSurfaceColor,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      width: 36,
      height: 36,
    },
  },
  controlIcon: {
    width: 22,
    height: 22,
    color: theme.videoPlayer.colors.videoSurfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  popperPaper: {
    marginTop: 0,
    marginBottom: 20,
    backgroundColor: theme.videoPlayer.colors.videoSurfaceColor,
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      marginTop: 20,
    },
  },
  menuListDivider: {
    backgroundColor: theme.videoPlayer.colors.videoSurfaceMutedColor,
  },
  bgPanel: {
    backgroundColor: theme.videoPlayer.colors.videoSurfaceColor,
  },
  textColor: {
    color: theme.videoPlayer.colors.videoSurfaceOnColor,
  },
}));

export default useStyles;

import firebase from 'utils/auth/firebase';
import * as RealmOperations from './operations';

const addFavorite = async ({ collection, trackId }) => {
  const userId = await firebase?.auth()?.currentUser?.uid;

  return RealmOperations.updateOne({
    collection,
    query: {
      _user_id: userId,
      track_id: trackId,
    },
    data: {
      _user_id: userId,
      track_id: trackId,
    },
  });
};

export default addFavorite;

import makeStyles from '@material-ui/core/styles/makeStyles';

const activeColorOptionBefore = {
  content: '" "',
  position: 'absolute',
  top: '-4px',
  right: '-4px',
  bottom: '-4px',
  left: '-4px',
  border: '4px solid currentColor',
  borderRadius: '50%',
  opacity: 0.6,
};

const useStyles = makeStyles((theme) => ({
  popover: {
    minWidth: '40%',
    padding: 16,
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundDark,
  },
  colorOptionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& >:not(:last-child)': {
      marginRight: 8,
    },
  },
  colorOptionBtn: {
    borderRadius: '50%',
    minWidth: 27,
    maxWidth: 27,
    width: 27,
    minHeight: 27,
    maxHeight: 27,
    height: 27,
    '&:hover:before': activeColorOptionBefore,
  },
  activeColorOptionBtn: {
    '&:before': activeColorOptionBefore,
  },
  textColor: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  textField: {
    marginTop: 16,
    marginBottom: 16,
    border: ({ activeTheme }) => `1px solid ${theme.reader[activeTheme]?.mainColors?.foregroundDark}`,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ebookDataPropType, readerRefPropType } from 'utils/prop-types';
import Icons from 'components/icons';
import PopperOverlay from 'components/common/popper-overlay';
import ControlButton from 'components/common/control-button';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import Portal from '@material-ui/core/Portal';
import { READER_THEMES } from 'utils/themes';
import BrightnessPanel from './brightness-panel';
import FontPanel from './font-panel';
import MarginPanel from './margin-panel';
import useStyles from './settings-menu.styles';
import LineHeightPanel from './lineheight-panel';
import AnimationPanel from './animation-panel';
import ThemePanel from './theme-panel';

const SettingsMenu = memo((props) => {
  const {
    readerRef,
    defaultTheme,
    ebookData: { activeTheme },
    controlBtnProps,
    isEbookReaderOpen,
    updateEBookDataAction,
  } = props;
  const theme = useTheme();
  const classes = useStyles({ activeTheme });

  const settingsRef = useRef(null);
  const [options, setOptions] = useState({});
  const [supportedOptions, setSupportedOptions] = useState({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettings = useCallback(() => {
    setIsSettingsOpen((currentFlag) => !currentFlag);
  }, []);

  const closeSettingsMenu = useCallback((event) => {
    if (settingsRef.current && settingsRef.current.contains(event?.target)) {
      return;
    }

    setIsSettingsOpen(false);
  }, []);

  const updateOptions = useCallback((newOptions) => {
    setOptions((currentOptions) => {
      const mergedOptions = { ...currentOptions, ...newOptions };

      readerRef.current.options.setOptions(mergedOptions);

      return mergedOptions;
    });
  }, []);

  const initTheme = useCallback(
    (palette) => {
      if (palette) {
        Object.entries(theme.reader).some(([themeName, { mainColors }]) => {
          if (Object.entries(palette).toString() === Object.entries(mainColors).toString()) {
            updateEBookDataAction({ activeTheme: themeName });

            return true;
          }

          return false;
        });
      } else {
        updateEBookDataAction({ activeTheme: defaultTheme ?? READER_THEMES.light });

        if (defaultTheme === READER_THEMES.dark) {
          updateOptions({ palette: theme.reader.dark.mainColors });
        }
      }
    },
    [defaultTheme],
  );

  const handlePointerdown = useCallback((event) => {
    if (event.frameworkComponent === 'READER_DOCUMENT' || event.frameworkComponent === 'READER_VIEW') {
      setIsSettingsOpen(false);
    }
  }, []);

  // Load options
  useEffect(() => {
    readerRef.current.options.getOptions().then((optionsData) => {
      setOptions(optionsData.options);
      setSupportedOptions(optionsData?.supportedOptions ?? {});

      initTheme(optionsData.options?.palette);

      readerRef.current.eventListeners.addListener('pointerdown', handlePointerdown);

      return () => {
        readerRef.current.eventListeners.removeListener('pointerdown', handlePointerdown);
      };
    });
  }, []);

  const sharedProps = {
    activeTheme,
    updateOptions,
    commonClasses: classes,
  };

  return (
    <>
      <ControlButton
        buttonRef={settingsRef}
        onClick={toggleSettings}
        icon={Icons.ReaderSettings}
        {...controlBtnProps}
      />
      <PopperOverlay
        isOpen={isSettingsOpen}
        placement="bottom"
        onClose={closeSettingsMenu}
        anchor={settingsRef.current}
        popperClassName={classes.paper}
      >
        <BrightnessPanel brightness={options?.customData?.brightness} {...sharedProps} />
        {supportedOptions?.fontSizeScaleFactor && (
          <FontPanel fontSize={options?.fontSizeScaleFactor} {...sharedProps} />
        )}
        {supportedOptions?.pageMarginHorizontal && (
          <MarginPanel margin={options?.pageMarginHorizontal} {...sharedProps} />
        )}
        {supportedOptions?.lineHeightScaleFactor && (
          <LineHeightPanel lineHeight={options?.lineHeightScaleFactor} {...sharedProps} />
        )}
        {supportedOptions?.palette && <ThemePanel palette={options?.palette} {...sharedProps} />}
        {supportedOptions?.enableAnimations && (
          <AnimationPanel {...sharedProps} isEnabled={options?.enableAnimations} />
        )}
      </PopperOverlay>
      {isEbookReaderOpen && (
        <Portal container={document.getElementById('root')}>
          <Box
            className={classes.brightnessOverlay}
            style={{ opacity: 1 - parseInt(`${options?.customData?.brightness ?? '100'}`, 10) / 100 }}
          />
        </Portal>
      )}
    </>
  );
});

SettingsMenu.propTypes = {
  readerRef: readerRefPropType,
  ebookData: ebookDataPropType,
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
  defaultTheme: PropTypes.oneOf([READER_THEMES.light, READER_THEMES.dark]),
  isEbookReaderOpen: PropTypes.bool.isRequired,
  updateEBookDataAction: PropTypes.func.isRequired,
};

export default SettingsMenu;

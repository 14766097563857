import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import logger from 'utils/logger';
import { isiOS } from 'utils/user-agent';
import { READER_THEMES } from 'utils/themes';
import { readerRefPropType } from 'utils/prop-types';
import useMediaQuery from 'hook/use-media-query';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Icons from 'components/icons';
import Loader from 'components/loader';
import ControlButton from 'components/common/control-button';
import { getAnnotationColorOptions } from 'components/ebook-reader/ebook-reader.utils';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './navigation-menu.styles';
import {
  TAB_OPTIONS,
  loadTOCItems,
  getEmptyMessage,
  NAVIGATION_TABS,
  loadBookmarkItems,
  loadAnnotationItems,
} from './navigation-menu.utils';

const MAX_ANNOTATION_TEXT_LENGTH = 40;

const TabPanel = memo((props) => {
  const classes = useStyles();
  const { children, value, index } = props;

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const NavigationMenu = memo((props) => {
  const theme = useTheme();
  const { readerRef, activeTheme } = props;
  const classes = useStyles({ activeTheme });
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState({
    [NAVIGATION_TABS.chapters]: false,
    [NAVIGATION_TABS.bookmarks]: false,
    [NAVIGATION_TABS.annotation]: false,
  });
  const [TOCItems, setTOCItems] = useState();
  const [bookmarkItems, setBookmarkItems] = useState();
  const [annotationItems, setAnnotationItems] = useState();
  const [activeTab, setActiveTab] = useState(NAVIGATION_TABS.chapters);
  const [isAnnotationExporting, setIsAnnotationExporting] = useState(false);

  const colorOptions = useMemo(() => getAnnotationColorOptions(theme, activeTheme), [theme, activeTheme]);

  const handleNavItemClick = useCallback(
    (selector) => () => {
      readerRef.current.navigation.goToSelector(selector);
    },
    [],
  );

  const handleTabChange = useCallback((_, value) => {
    setActiveTab(value);
  }, []);

  const loadAndSetNavigationItems = useCallback(() => {
    setIsLoading({
      [NAVIGATION_TABS.chapters]: true,
      [NAVIGATION_TABS.bookmarks]: true,
      [NAVIGATION_TABS.annotation]: true,
    });

    loadTOCItems(readerRef).then((items) => {
      setTOCItems(items);
      setIsLoading((current) => ({ ...current, [NAVIGATION_TABS.chapters]: false }));
    });

    loadBookmarkItems(readerRef).then((items) => {
      setBookmarkItems(items);
      setIsLoading((current) => ({ ...current, [NAVIGATION_TABS.bookmarks]: false }));
    });

    loadAnnotationItems(readerRef).then((items) => {
      setAnnotationItems(items);
      setIsLoading((current) => ({ ...current, [NAVIGATION_TABS.annotation]: false }));
    });
  }, []);

  // Reload nav items whenever timeline changes, e.g. when the page numbers change
  const handleTimelineRecalculated = useCallback(() => {
    loadAndSetNavigationItems();
  }, []);

  const getHighlightColorStyle = useCallback(
    (item) => {
      const color = colorOptions.find((option) => option.readerHighlightColorId === item.annotationHighlightColorId);

      return { backgroundColor: color?.value };
    },
    [colorOptions],
  );

  useEffect(() => {
    loadAndSetNavigationItems();
    readerRef.current.eventListeners.addListener('timelineRecalculated', handleTimelineRecalculated);

    return () => {
      readerRef.current.eventListeners.removeListener('timelineRecalculated', handleTimelineRecalculated);
    };
  }, []);

  const exportAnnotationsToRFT = useCallback(() => {
    setIsAnnotationExporting(true);
    readerRef.current?.exporter
      ?.exportAnnotationsToRtf()
      .then((response) => {
        setIsAnnotationExporting(false);
        logger.log('NavigationMenu', 'Annotations were exported successfully.', response);
      })
      .catch((error) => {
        setIsAnnotationExporting(false);
        logger.error('NavigationMenu', 'Error while exporting annotations.', error);
      });
  }, []);

  const TAB_TO_VALUES = {
    [NAVIGATION_TABS.chapters]: TOCItems,
    [NAVIGATION_TABS.bookmarks]: bookmarkItems,
    [NAVIGATION_TABS.annotation]: annotationItems,
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.tabHeader}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          variant="fullWidth"
          value={activeTab}
          onChange={handleTabChange}
        >
          {TAB_OPTIONS.map(({ value, label, ...restProps }) => (
            <Tab key={value} value={value} label={t(label)} {...restProps} />
          ))}
        </Tabs>
      </Box>
      {TAB_OPTIONS.map(({ value }) => {
        const items = TAB_TO_VALUES[value];
        const emptyMessage = !items?.length ? getEmptyMessage(value) : null;

        return (
          <TabPanel key={value} value={activeTab} index={value}>
            {isLoading[value] && <Loader />}
            {emptyMessage && (
              <>
                <Typography noWrap className={classes.emptyMessageTitle}>
                  {t(emptyMessage.title)}
                </Typography>
                <Typography noWrap className={classes.emptyMessageSubtitle}>
                  {t(emptyMessage.subtitle)}
                </Typography>
              </>
            )}
            {!!items?.length && (
              <List>
                {items.map((item) => (
                  <ListItem
                    button
                    key={item.id}
                    disablePadding
                    className={classes.navItem}
                    onClick={handleNavItemClick(item.selector)}
                  >
                    <Typography noWrap className={classes.navItemTitle}>
                      {item?.annotationHighlightColorId !== undefined && (
                        <Box className={classes.itemColor} style={getHighlightColorStyle(item)} />
                      )}
                      {item.title}
                    </Typography>
                    {!!item?.annotationNote && (
                      <Typography noWrap className={classes.navItemSubTitle}>
                        {item.annotationNote.length > MAX_ANNOTATION_TEXT_LENGTH
                          ? `${item.annotationNote.substring(0, MAX_ANNOTATION_TEXT_LENGTH)}...`
                          : item.annotationNote}
                      </Typography>
                    )}
                    <Typography noWrap className={classes.navItemSubTitle}>
                      {t('generic_format_page', { arg: item.timelinePageIndex })}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
            {value === NAVIGATION_TABS.annotation && !!items?.length && (
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={isAnnotationExporting}
                onClick={exportAnnotationsToRFT}
                className={classes.exportAnnotations}
              >
                {t`ebook_annotations_button_export`}
              </Button>
            )}
          </TabPanel>
        );
      })}
    </Box>
  );
});

NavigationMenu.propTypes = {
  readerRef: readerRefPropType,
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
};

const NavigationMenuWrapper = memo((props) => {
  const classes = useStyles();
  const isIOSDevice = isiOS();
  const isTablet = useMediaQuery('tablet');
  const { controlBtnProps, ...navigationMenuProps } = props;
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const toggleDrawer = useCallback(
    (openState) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setIsNavigationOpen((currentState) => openState ?? !currentState);
    },
    [],
  );

  return (
    <>
      <ControlButton onClick={toggleDrawer()} icon={Icons.ReaderTOC} {...controlBtnProps} />
      <SwipeableDrawer
        disableScrollLock
        disableSwipeToOpen
        disableDiscovery={isIOSDevice}
        disableBackdropTransition={!isIOSDevice}
        anchor={isTablet ? 'bottom' : 'right'}
        open={isNavigationOpen}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <NavigationMenu {...navigationMenuProps} />
      </SwipeableDrawer>
    </>
  );
});

NavigationMenuWrapper.propTypes = {
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
};

export default NavigationMenuWrapper;

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  readerViewContainer: {
    width: 'calc(100% - 120px)',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    maxHeight: 'calc(100% - 99px - 99px)',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 'calc(100% - 40px)',
    },
  },
  readerView: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20,
    tabIndex: '-1',
    position: 'absolute',
  },
  arrowBg: {
    width: 48,
    height: 290,
    opacity: 0.5,
    display: 'flex',
    borderRadius: 6,
    top: 'calc(50% - 145px)',
    position: 'absolute',
    transition: 'opacity 0.3s',
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundDark,
  },
  arrowIconBtn: {},
  leftArrow: {
    left: '-54px',
  },
  rightArrow: {
    right: '-54px',
  },
  icon: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  textThemeColor: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
}));

export default useStyles;

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ebookSettingsSharedProps } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import useStyles, { AnimationSwitch } from './animation-panel.styles';

const AnimationPanel = memo(({ isEnabled, activeTheme, updateOptions }) => {
  const classes = useStyles({ activeTheme });
  const { t } = useTranslation();

  const toggleSwitch = useCallback(() => {
    updateOptions({ enableAnimations: !isEnabled });
  }, [isEnabled]);

  return (
    <Box className={classes.animationSwitchContainer}>
      <FormControlLabel
        labelPlacement="start"
        className={classes.animationSwitchLabel}
        control={
          <AnimationSwitch checked={isEnabled} onChange={toggleSwitch} name={t`ebook_option_enable_animations`} />
        }
        label={<Typography className={classes.animationSwitchText}>{t`ebook_option_enable_animations`}</Typography>}
      />
    </Box>
  );
});

AnimationPanel.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  ...ebookSettingsSharedProps,
};

export default AnimationPanel;

import React, { memo, useCallback } from 'react';
import { READER_THEMES } from 'utils/themes';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons from 'components/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from './theme-panel.styles';

const ThemePanel = memo(({ activeTheme, commonClasses, updateEBookDataAction }) => {
  const classes = useStyles();

  const handleThemeChange = useCallback(
    (selectedTheme) => () => {
      updateEBookDataAction({ activeTheme: selectedTheme });
    },
    [],
  );

  return (
    <Box className={classes.colorThemeContainer}>
      <IconButton onClick={handleThemeChange(READER_THEMES.light)} className={commonClasses.iconBtn}>
        <Icons.ReaderThemeLight
          className={clsx(
            commonClasses.iconMd,
            classes.lightThemeIcon,
            activeTheme === READER_THEMES.light && classes.activeLightThemeIcon,
            activeTheme === READER_THEMES.sepia && classes.activeLightSepiaThemeIcon,
          )}
        />
      </IconButton>
      <IconButton onClick={handleThemeChange(READER_THEMES.sepia)} className={commonClasses.iconBtn}>
        <Icons.ReaderThemeSepia
          className={clsx(
            commonClasses.iconMd,
            classes.sepiaThemeIcon,
            activeTheme === READER_THEMES.sepia && classes.activeSepiaThemeIcon,
          )}
        />
      </IconButton>
      <IconButton onClick={handleThemeChange(READER_THEMES.dark)} className={commonClasses.iconBtn}>
        <Icons.ReaderThemeDark
          className={clsx(
            commonClasses.iconMd,
            classes.darkThemeIcon,
            activeTheme === READER_THEMES.dark && classes.activeDarkThemeIcon,
          )}
        />
      </IconButton>
    </Box>
  );
});

ThemePanel.propTypes = {
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
  commonClasses: PropTypes.shape({
    iconSm: PropTypes.string.isRequired,
    iconMd: PropTypes.string.isRequired,
    iconBtn: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
  }).isRequired,
  updateEBookDataAction: PropTypes.func.isRequired,
};

export default ThemePanel;

import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { generateUserSubscriptionPortalLink } from 'utils/firestore';
import useContentPermission from 'hook/use-content-permission';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import Loader from 'components/loader';
import PopperOverlay from 'components/common/popper-overlay';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useStyles from './account-menu.styles';

const AccountMenu = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { displayName, logoutAction, firebaseFunctionLocation } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { hasSubscriptionFlow, hasSubscriptionPermission } = useContentPermission();

  const menuAnchorRef = useRef(null);
  const [isPortalLinkLoading, setIsPortalLinkLoading] = useState(false);

  const handleSubscriptionAccount = useCallback(() => {
    setIsPortalLinkLoading(true);
    generateUserSubscriptionPortalLink(firebaseFunctionLocation)
      .then((userPortalLink) => {
        window.location.href = userPortalLink;
      })
      .catch(() => setIsPortalLinkLoading(false));
  }, [firebaseFunctionLocation]);

  const handleToggle = useCallback(() => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  }, [setIsMenuOpen]);

  const handleCloseMenu = useCallback(
    (event) => {
      if (menuAnchorRef.current && menuAnchorRef.current.contains(event?.target)) {
        return;
      }

      setIsMenuOpen(false);
    },
    [setIsMenuOpen],
  );

  const handleLogoutClick = useCallback(() => {
    logoutAction();
  }, []);

  return (
    <>
      <Button
        ref={menuAnchorRef}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.navButton}
        aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
      >
        {t`MAIN_TABS_SETTINGS`}
      </Button>
      <PopperOverlay
        isOpen={isMenuOpen}
        placement="bottom-end"
        onClose={handleCloseMenu}
        anchor={menuAnchorRef.current}
        popperClassName={classes.paper}
      >
        <MenuList className={classes.menuList}>
          {hasSubscriptionFlow && hasSubscriptionPermission && (
            <>
              <MenuItem
                disabled={isPortalLinkLoading}
                onClick={handleSubscriptionAccount}
                className={clsx(classes.menuItem, classes.menuItemText)}
              >
                {t`settings_label_manage`}
                {isPortalLinkLoading && (
                  <Box className={classes.itemLoader}>
                    <Loader />
                  </Box>
                )}
              </MenuItem>
              <Divider />
            </>
          )}
          <MenuItem className={clsx(classes.menuItem, classes.menuItemText)}>
            <Typography noWrap component="span" className={classes.menuItemText}>
              {displayName}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogoutClick} className={clsx(classes.menuItem, classes.menuItemText)}>
            {t`settings_label_logout`}
          </MenuItem>
        </MenuList>
      </PopperOverlay>
    </>
  );
};

AccountMenu.propTypes = {
  displayName: PropTypes.string,
  logoutAction: PropTypes.func.isRequired,
  firebaseFunctionLocation: PropTypes.string,
};

AccountMenu.defaultProps = {
  displayName: '',
};

export default AccountMenu;

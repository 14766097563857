import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'hook/use-media-query';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Portal from '@material-ui/core/Portal';
import Navigation from 'components/player-components/navigation';
import Timeline from 'components/player-components/timeline';
import useStyles from './mini-player.styles';
import Options from './components/options';
import TrackInfo from './components/track-info';
import NavigationMobile from './components/navigation-mobile';

const MiniPlayer = memo((props) => {
  const classes = useStyles();
  const isTablet = useMediaQuery('tablet');
  const { duration, currentTime, playerRef, isMiniPlayerOpen } = props;

  return (
    <Portal container={document.getElementById('root')}>
      <Slide mountOnEnter unmountOnExit in={isMiniPlayerOpen} direction="up">
        <Box className={classes.playerContainer}>
          <Timeline
            withInfoOnHover
            timePosition="top"
            duration={duration}
            playerRef={playerRef}
            currentTime={currentTime}
          />
          <Box className={classes.playerContent}>
            <TrackInfo isTablet={isTablet} />
            {isTablet ? (
              <NavigationMobile />
            ) : (
              <>
                <Navigation playerRef={playerRef} className={classes.navigation} />
                <Options playerRef={playerRef} />
              </>
            )}
          </Box>
        </Box>
      </Slide>
    </Portal>
  );
});

MiniPlayer.propTypes = {
  ...playerSharedPropTypes,
  isMiniPlayerOpen: PropTypes.bool.isRequired,
};

export default MiniPlayer;

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { searchTracks } from 'api/search';
import { Fade } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Portal from '@material-ui/core/Portal';
import useDebounce from 'hook/use-debounce';
import Loader from 'components/loader';
import { SearchIcon } from 'components/icons';
import clsx from 'clsx';
import ListResult from './list-result';
import useStyles from './search-input.styles';

export const DEBOUNCE_DELAY = 500;
export const LENGTH_TO_SEARCH = 3;

const SearchInput = memo(({ collapsed, withMiniPlayer, availableSearchWidth, permissions }) => {
  const classes = useStyles({ collapsed, withMiniPlayer, availableSearchWidth });
  const location = useLocation();
  const { t } = useTranslation();

  const inputRef = useRef();
  const currentLocation = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isInputOpened, setIsInputOpened] = useState(!collapsed);
  const [isResultOverlayOpened, setIsResultOverlayOpened] = useState(false);

  const debouncedSearchValue = useDebounce(inputValue, DEBOUNCE_DELAY);

  const clearSearchInput = useCallback(
    (event) => {
      event.preventDefault();
      setInputValue('');
      inputRef.current.focus();
    },
    [setInputValue],
  );

  const openSearchInput = useCallback(
    (event) => {
      event.stopPropagation();
      setIsInputOpened(true);
      inputRef.current.focus();
    },
    [setIsInputOpened],
  );

  const handleInputChange = useCallback(
    (event) => {
      const { value } = event.target;

      setInputValue(value);

      if (value) {
        setErrorMessage(null);
        setIsResultOverlayOpened(true);
      }
    },
    [setInputValue],
  );

  const handleOnBlurInput = useCallback(() => {
    if (!inputValue) {
      if (collapsed) {
        setIsInputOpened(false);
      }

      setIsResultOverlayOpened(false);
    }
  }, [inputValue]);

  useEffect(() => {
    document.body.style.overflowY = isResultOverlayOpened ? 'hidden' : 'unset';
  }, [isResultOverlayOpened]);

  useEffect(() => {
    if (
      currentLocation.current &&
      currentLocation.current !== location.pathname &&
      (searchResults.length || isInputOpened || isResultOverlayOpened || isLoading)
    ) {
      setInputValue('');
      setIsLoading(false);
      setSearchResults([]);
      setIsResultOverlayOpened(false);

      if (collapsed) {
        setIsInputOpened(false);
      }
    }
    currentLocation.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    if (debouncedSearchValue.length >= LENGTH_TO_SEARCH) {
      if (!searchResults.length) {
        setIsLoading(true);
      }

      setErrorMessage(null);
      setIsResultOverlayOpened(true);

      searchTracks(debouncedSearchValue, permissions)
        .then((result) => {
          if (result.length) {
            setSearchResults(result);
          } else {
            setSearchResults([]);
            setErrorMessage(t`search_empty_message_noresults`);
          }
        })
        .catch(() => {
          setErrorMessage(t`search_empty_heading_error`);
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setSearchResults([]);
      setErrorMessage(t`search_empty_message_blank`);
    }
  }, [debouncedSearchValue, permissions]);

  return (
    <>
      <Box className={classes.container}>
        {!isInputOpened && collapsed && <SearchIcon onClick={openSearchInput} className={classes.searchIcon} />}
        <Input
          disableUnderline
          inputRef={inputRef}
          variant="standard"
          value={inputValue}
          onBlur={handleOnBlurInput}
          onChange={handleInputChange}
          placeholder={t`MAIN_TABS_SEARCH`}
          className={clsx(classes.input, isInputOpened && classes.openedInput)}
          startAdornment={
            isInputOpened && (
              <InputAdornment position="start">
                <SearchIcon onClick={openSearchInput} className={classes.searchIcon} />
              </InputAdornment>
            )
          }
          endAdornment={
            !!inputValue && (
              <InputAdornment position="end">
                <CloseIcon onMouseDown={clearSearchInput} className={classes.closeIcon} />
              </InputAdornment>
            )
          }
          inputProps={{
            style: { maxWidth: isInputOpened ? 'unset' : 0 },
          }}
        />
      </Box>
      <Portal container={document.getElementById('root')}>
        <Fade mountOnEnter unmountOnExit in={isResultOverlayOpened} timeout={500}>
          <Box className={classes.resultListContainer}>
            <Box className={classes.resultListContentContainer}>
              {isLoading && <Loader />}
              {errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
              {!!searchResults.length && (
                <Box className={classes.resultListContent}>
                  <ListResult tracks={searchResults} />
                </Box>
              )}
            </Box>
          </Box>
        </Fade>
      </Portal>
    </>
  );
});

SearchInput.propTypes = {
  collapsed: PropTypes.bool,
  withMiniPlayer: PropTypes.bool.isRequired,
  availableSearchWidth: PropTypes.number,
};

SearchInput.defaultProps = {
  collapsed: true,
};

export default SearchInput;

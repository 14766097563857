/* eslint-disable import/no-cycle */
import { createRoutine } from 'redux-saga-routines';
import { select, put, call, takeLatest } from 'redux-saga/effects';
import logger from 'utils/logger';
import ROUTES from 'utils/routes';
import addHistory from 'utils/realm/add-history';
import { isEbookContentType } from 'utils/assets';
import getUserCollection from 'utils/realm/get-user-collection';
import { getPersistentData, HISTORY_PERSIST_KEY, setPersistentData } from 'utils/localstorage';
import { getRealmDatabase, isRealmSupported } from '../realm';
import { getActiveTrackIndex, getQueue } from '../player';
import { getUserId } from '../auth';

const HISTORY_COLLECTION_NAME = 'History';

export const getHistoryRoutine = createRoutine('GET_HISTORY');
export const addToHistoryRoutine = createRoutine('ADD_TO_HISTORY');

export const initialState = Object.freeze({
  history: {},
});

export const historyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getHistoryRoutine.SUCCESS:
    case addToHistoryRoutine.SUCCESS:
      return {
        ...state,
        history: payload,
      };
    default:
      return { ...state };
  }
};

export function* getHistorySaga({ database } = {}) {
  const userId = yield select(getUserId);
  const isRealmDBAvailable = yield select(isRealmSupported);

  try {
    let userHistoryItems;

    if (isRealmDBAvailable) {
      const databaseInstance = database ?? (yield select(getRealmDatabase));

      // Skip if database is not initialized yet
      if (!databaseInstance) {
        return null;
      }

      const historyCollection = databaseInstance.collection(HISTORY_COLLECTION_NAME);

      const dbUserHistoryItems = yield call(getUserCollection, historyCollection);
      userHistoryItems = dbUserHistoryItems.reduce(
        (acc, item) => ({
          ...acc,
          [`${item.track_id}_${item.asset_id}`]: {
            trackId: item.track_id,
            assetId: item.asset_id,
            timestamp: item.timestamp,
            progress: item.progress,
          },
        }),
        {},
      );

      logger.log('getHistorySaga', 'Loaded history items from Realm DB.', userHistoryItems);
    } else {
      const allHistoryItems = getPersistentData(HISTORY_PERSIST_KEY) ?? {};
      userHistoryItems = allHistoryItems[userId] ?? [];

      logger.log('getHistorySaga', 'Loaded history items from localstorage.', userHistoryItems);
    }

    yield put(getHistoryRoutine.success(userHistoryItems));

    return userHistoryItems;
  } catch (error) {
    logger.error('getHistorySaga', 'Unable to get history items', error);
  }

  return null;
}

export function* addToHistorySaga({ payload: data }) {
  const { progress, duration } = data;
  const userId = yield select(getUserId);
  const isRealmDBAvailable = yield select(isRealmSupported);
  const queue = yield select(getQueue);
  const activeQueueIndex = yield select(getActiveTrackIndex);
  const activeQueueItem = queue?.[activeQueueIndex];
  const { trackId, assetId, contentType } = activeQueueItem ?? {};
  const newHistoryItem = {
    trackId,
    assetId,
    duration,
    timestamp: new Date(),
    progress: Math.floor(progress || 0),
    progressPercent: progress / duration,
    isFinished: isEbookContentType({ contentType }) ? false : duration - progress < 10,
  };

  try {
    if (Number.isInteger(trackId) && Number.isInteger(assetId)) {
      if (isRealmDBAvailable) {
        const database = yield select(getRealmDatabase);
        const historyCollection = database.collection(HISTORY_COLLECTION_NAME);

        yield call(addHistory, { collection: historyCollection, ...newHistoryItem });

        logger.log('addToHistorySaga', 'Added new item to Realm DB.', newHistoryItem);
      } else {
        const allHistory = getPersistentData(HISTORY_PERSIST_KEY) ?? {};

        const newHistory = {
          ...allHistory,
          [userId]: {
            ...allHistory?.[userId],
            [`${trackId}_${assetId}`]: newHistoryItem,
          },
        };

        setPersistentData(HISTORY_PERSIST_KEY, newHistory);

        logger.log('addToHistorySaga', 'Added new item to localstorage.', newHistoryItem);
      }

      if (window?.location?.pathname?.includes(ROUTES.library)) {
        yield call(getHistorySaga);
      }
    }
  } catch (error) {
    logger.error('addToHistorySaga', 'Unable to add history item', error);
  }
}

export function* watchHistorySagas() {
  yield takeLatest(getHistoryRoutine.REQUEST, getHistorySaga);
  yield takeLatest(addToHistoryRoutine.REQUEST, addToHistorySaga);
}

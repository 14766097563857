import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  controlPanelContainer: {
    display: 'flex',
    flexGrow: 1,
    marginTop: 21,
    marginRight: 60,
    justifyContent: 'flex-end',
    '& >:not(:last-child)': {
      marginRight: 18,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginTop: 28,
      marginRight: 20,
    },
  },
  controlButton: {
    width: 42,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.colors.surfaceColor,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: darken(theme.palette.colors.surfaceColor, 0.1),
      },
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.palette.colors.surfaceColor,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 36,
      height: 36,
    },
  },
  bookmarkIcon: {
    color: theme.palette.colors.primaryColor,
  },
  controlIcon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  popperPaper: {
    marginTop: 19,
  },
  moreMenu: {
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  moreMenuItem: {
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  moreMenuText: {
    color: theme.palette.colors.surfaceOnColor,
  },
}));

export default useStyles;

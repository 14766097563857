import makeStyles from '@material-ui/core/styles/makeStyles';
import { HEADER_NAV_ITEM_WIDTH, HEADER_HEIGHT, CONTENT_PADDING, HEADER_LOGO_IMAGE_WIDTH } from 'utils/themes/sizes';

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: 9,
  },
  toolBar: {
    height: HEADER_HEIGHT,
    display: 'flex',
    width: '100%',
    maxWidth: 1024,
    margin: '0 auto',
    paddingLeft: CONTENT_PADDING,
    paddingRight: CONTENT_PADDING,
    paddingTop: 13,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navButton: {
    width: HEADER_NAV_ITEM_WIDTH,
    height: 36,
    fontSize: 12,
    color: theme.palette.colors.secondaryOnColor,
    backgroundColor: theme.palette.colors.secondaryColor,
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    cursor: 'pointer',
    marginRight: 20,
    color: theme.palette.colors.surfaceOnColor,
  },
  logoImage: {
    width: HEADER_LOGO_IMAGE_WIDTH,
  },
}));

export default useStyles;

import { connect } from 'react-redux';
import { logoutRoutine } from 'redux-state/ducks/auth';
import AccountMenu from './account-menu.component';

const mapStateToProps = ({ app, firebase }) => ({
  displayName: firebase?.auth?.displayName || firebase?.auth?.email || '-',
  firebaseFunctionLocation: app?.config?.firebaseFunctionLocation,
});

const mapDispatchToProps = {
  logoutAction: logoutRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/compat/app';
import { createFirestoreInstance } from 'redux-firestore';

import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

const ERROR_PARSE_REGEXP = /^Firebase:(.*).\((.*)\).$/;

export const parseFirebaseError = (error) => {
  if (error.message) {
    const [, errorMessage] = error.message.match(ERROR_PARSE_REGEXP);

    return {
      ...error,
      message: errorMessage.trim(),
    };
  }

  return error;
};

const profileSpecificProps = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableRedirectHandling: false,
  resetBeforeLogin: false,
};

export const initFirebase = (config) => {
  firebase.initializeApp(config);
};

export const getReactReduxFirebaseProps = (store) => ({
  firebase,
  config: profileSpecificProps,
  dispatch: store.dispatch,
  createFirestoreInstance,
});

export default firebase;

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ebookDataPropType } from 'utils/prop-types';
import Icons from 'components/icons';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import useStyles from './brightness-panel.styles';

const BrightnessPanel = memo(({ brightness, setBrightness, ebookData: { activeTheme } }) => {
  const classes = useStyles({ activeTheme });

  const handleBrightnessChange = useCallback(
    (_, value) => {
      setBrightness(`${value}`);
    },
    [setBrightness],
  );

  return (
    <>
      <Box className={classes.brightnessContainer}>
        <Icons.ReaderBrightnessLow className={classes.icon} />
        <Slider
          min={20}
          max={100}
          color="primary"
          value={parseInt(brightness, 10)}
          onChange={handleBrightnessChange}
          aria-label="brightness-indicator"
          className={classes.brightnessSlider}
          classes={{
            rail: classes.brightnessSliderRail,
            thumb: classes.brightnessSliderIndicator,
          }}
        />
        <Icons.ReaderBrightnessHigh className={classes.icon} />
      </Box>
    </>
  );
});

BrightnessPanel.propTypes = {
  ebookData: ebookDataPropType,
  brightness: PropTypes.string.isRequired,
  setBrightness: PropTypes.func.isRequired,
};

export default BrightnessPanel;

import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEbookContentType, isEpubEbookType, isPDFEbookType, isPubhubEbookType } from 'utils/assets';
import { ebookDataPropType, queuePropType } from 'utils/prop-types';
import useSuspendPinchZoom from 'hook/use-suspend-pinch-zoom';
import useWindowSize from 'hook/use-window-size';
import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';
import Portal from '@material-ui/core/Portal';
import QueuePersistence from 'components/queue-persistence';
import PDFReader from './pdf-reader';
import EpubReader from './epub-reader';
import PubhubReader from './pubhub-reader';
import useStyles from './ebook-reader.styles';
import MiniPlayer from './components/mini-player';

const EBookReader = memo((props) => {
  const {
    queue,
    activeIndex,
    ebookData: { activeTheme },
    isEbookReaderOpen,
  } = props;

  const { width, height } = useWindowSize();
  const activeQueueItem = queue[activeIndex];
  const isPDFReader = isPDFEbookType(activeQueueItem);
  const isEbookAsset = isEbookContentType(activeQueueItem);
  const isEpubReader = isEpubEbookType(activeQueueItem);
  const isPubhubReader = isPubhubEbookType(activeQueueItem);
  const containerRef = useSuspendPinchZoom({ trigger: isEbookReaderOpen });
  const classes = useStyles({ activeTheme, isPDFReader, isEpubReader, isPubhubReader });

  const toggleDocumentScrollBar = useCallback(() => {
    document.body.style.overflowY = isEbookReaderOpen ? 'hidden' : 'unset';
  }, [isEbookReaderOpen]);

  useEffect(() => {
    document.body.style.overflowY = isEbookReaderOpen ? 'hidden' : 'unset';
  }, [width, height, isEbookReaderOpen]);

  return (
    <>
      <Portal container={document.getElementById('root')}>
        <Zoom
          mountOnEnter
          timeout={470}
          in={isEbookReaderOpen}
          onEntered={toggleDocumentScrollBar}
          onExited={toggleDocumentScrollBar}
        >
          <Box ref={containerRef} className={classes.container}>
            <Box key={activeQueueItem?.id} className={classes.readerViewContentContainer}>
              {isPDFReader && <PDFReader />}
              {isEpubReader && <EpubReader />}
              {isPubhubReader && <PubhubReader />}
            </Box>
          </Box>
        </Zoom>
      </Portal>
      {isEbookAsset && (
        <>
          <MiniPlayer />
          <QueuePersistence />
        </>
      )}
    </>
  );
});

EBookReader.propTypes = {
  queue: queuePropType,
  activeIndex: PropTypes.number,
  ebookData: ebookDataPropType,
  isEbookReaderOpen: PropTypes.bool.isRequired,
};

export default EBookReader;

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';
import clsx from 'clsx';
import useStyles from './timeline.styles';

const EMPTY_TIME_VALUE = '00:00';
const TIMELINE_VALUE_MULTIPLIER = 10000000;

const formatTime = (time) => {
  const totalSeconds = parseInt(time, 10);

  if (Number.isNaN(totalSeconds)) {
    return EMPTY_TIME_VALUE;
  }

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds - minutes * 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};

const TimeLine = memo((props) => {
  const classes = useStyles();
  const {
    duration,
    currentTime,
    timePosition,
    playerRef,
    withInfoOnHover,
    railClassName,
    elapsedTimeClassName,
    totalTimeClassName,
  } = props;

  const [isSeeking, setIsSeeking] = useState(false);
  const [isTimelineInfoVisible, setIsTimelineInfoVisible] = useState(!withInfoOnHover);
  const [timelineValue, setTimelineValue] = useState((currentTime / duration) * TIMELINE_VALUE_MULTIPLIER);

  const handleTimelineHover = useCallback(
    (flag) => () => {
      setIsTimelineInfoVisible(flag);
    },
    [],
  );

  const handleTimelineChange = useCallback((_, value) => {
    setIsSeeking(true);
    setTimelineValue(value);
  }, []);

  const handleTimelineChangeCommitted = useCallback(
    (_, value) => {
      const newValue = (value / TIMELINE_VALUE_MULTIPLIER) * duration;

      playerRef.current.currentTime = newValue;
      setIsSeeking(false);
    },
    [duration],
  );

  useEffect(() => {
    if (!isSeeking) {
      setTimelineValue((currentTime / duration) * TIMELINE_VALUE_MULTIPLIER);
    }
  }, [currentTime]);

  const handlers = useMemo(
    () =>
      withInfoOnHover
        ? {
            onMouseEnter: handleTimelineHover(true),
            onMouseLeave: handleTimelineHover(false),
          }
        : {},
    [withInfoOnHover],
  );

  return (
    <>
      <Slider
        min={0}
        max={TIMELINE_VALUE_MULTIPLIER - 1}
        value={timelineValue}
        color="primary"
        onChange={handleTimelineChange}
        onChangeCommitted={handleTimelineChangeCommitted}
        aria-label="time-indicator"
        className={classes.trackTimeline}
        classes={{
          rail: railClassName,
          thumb: clsx(classes.trackTimelineIndicator, isTimelineInfoVisible && classes.onTimelineHover),
        }}
        {...handlers}
      />
      <Box
        className={clsx(
          classes.timeText,
          classes.currentTime,
          timePosition === 'top' && classes.timeTextTopPosition,
          timePosition === 'bottom' && classes.timeTextBottomPosition,
          isTimelineInfoVisible && classes.onTimelineHover,
          elapsedTimeClassName,
        )}
      >
        {formatTime(currentTime)}
      </Box>
      <Box
        className={clsx(
          classes.timeText,
          classes.totalTime,
          timePosition === 'top' && classes.timeTextTopPosition,
          timePosition === 'bottom' && classes.timeTextBottomPosition,
          isTimelineInfoVisible && classes.onTimelineHover,
          totalTimeClassName,
        )}
      >
        {formatTime(duration)}
      </Box>
    </>
  );
});

TimeLine.propTypes = {
  duration: playerSharedPropTypes.duration,
  currentTime: playerSharedPropTypes.currentTime,
  playerRef: playerSharedPropTypes.playerRef,
  withInfoOnHover: PropTypes.bool,
  timePosition: PropTypes.oneOf(['top', 'bottom']),
  railClassName: PropTypes.string,
  elapsedTimeClassName: PropTypes.string,
  totalTimeClassName: PropTypes.string,
};

TimeLine.defaultProps = {
  withInfoOnHover: false,
  timePosition: 'top',
  railClassName: '',
  elapsedTimeClassName: '',
  totalTimeClassName: '',
};

export default TimeLine;

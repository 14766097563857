import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons from 'components/icons';
import clsx from 'clsx';
import useStyles from './margin-panel.styles';

export const MARGIN_VALUES = Object.freeze({
  xl: 0.4,
  lg: 0.3,
  md: 0.2,
  sm: 0.1,
});

const MarginPanel = memo(({ margin, setMargin, commonClasses }) => {
  const classes = useStyles();

  const changeMargin = useCallback(
    (marginSize) => () => {
      const currentSettings = window?.READIUM?.reader?.viewerSettings();
      const { syntheticSpread } = currentSettings;
      const container = document.querySelector('#epub-reader-container');
      const containerWidth = container.offsetWidth;
      const columnGap = containerWidth * marginSize;

      if (syntheticSpread === 'single') {
        window?.READIUM?.reader?.updateSettings?.({ columnGap });
      } else if (syntheticSpread === 'double') {
        window?.READIUM?.reader?.updateSettings?.({ columnGap: columnGap / 2 });
      }

      setMargin(marginSize);
    },
    [],
  );

  return (
    <Box className={classes.marginContainer}>
      <IconButton onClick={changeMargin(MARGIN_VALUES.xl)} className={commonClasses.iconBtn}>
        <Icons.ReaderMarginXl
          className={clsx(commonClasses.iconSm, margin === MARGIN_VALUES.xl && commonClasses.activeIcon)}
        />
      </IconButton>
      <IconButton onClick={changeMargin(MARGIN_VALUES.lg)} className={commonClasses.iconBtn}>
        <Icons.ReaderMarginLg
          className={clsx(commonClasses.iconSm, margin === MARGIN_VALUES.lg && commonClasses.activeIcon)}
        />
      </IconButton>
      <IconButton onClick={changeMargin(MARGIN_VALUES.md)} className={commonClasses.iconBtn}>
        <Icons.ReaderMarginMd
          className={clsx(commonClasses.iconSm, margin === MARGIN_VALUES.md && commonClasses.activeIcon)}
        />
      </IconButton>
      <IconButton onClick={changeMargin(MARGIN_VALUES.sm)} className={commonClasses.iconBtn}>
        <Icons.ReaderMarginSm
          className={clsx(commonClasses.iconSm, margin === MARGIN_VALUES.sm && commonClasses.activeIcon)}
        />
      </IconButton>
    </Box>
  );
});

MarginPanel.propTypes = {
  margin: PropTypes.oneOf(Object.values(MARGIN_VALUES)).isRequired,
  setMargin: PropTypes.func.isRequired,
  commonClasses: PropTypes.shape({
    iconSm: PropTypes.string.isRequired,
    iconMd: PropTypes.string.isRequired,
    iconBtn: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
  }).isRequired,
};

export default MarginPanel;

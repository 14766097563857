import makeStyles from '@material-ui/core/styles/makeStyles';

export const FULL_PLAYER_HEADER = 104;
export const FULL_PLAYER_NAVIGATION = 108;
export const FULL_PLAYER_TIMELINE = 108;

const useStyles = makeStyles((theme) => ({
  galleryContainer: {
    height: `calc(100vh - ${FULL_PLAYER_HEADER}px - ${FULL_PLAYER_NAVIGATION}px - ${FULL_PLAYER_TIMELINE}px)`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      height: `calc(100vh - ${FULL_PLAYER_HEADER}px - ${FULL_PLAYER_NAVIGATION}px - ${FULL_PLAYER_TIMELINE}px - 85px)`,
    },
  },
  galleryCarousel: {},
  image: {
    width: ({ cardSize }) => cardSize ?? '100%',
    height: ({ cardSize }) => cardSize ?? '100%',
    maxHeight: ({ cardSize }) => cardSize ?? '100%',
    backgroundSize: 'cover',
    borderRadius: 6,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

export default useStyles;

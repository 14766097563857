import React, { memo, useCallback } from 'react';
import { ebookSettingsSharedProps } from 'utils/prop-types';
import { READER_THEMES } from 'utils/themes';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons from 'components/icons';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './theme-panel.styles';

const getPaletteMainColors = (theme, palette) => {
  switch (theme) {
    case READER_THEMES.light:
      return null;
    case READER_THEMES.dark:
    case READER_THEMES.sepia:
      return palette?.mainColors;
    default:
      return null;
  }
};

const ThemePanel = memo(({ activeTheme, updateOptions, commonClasses, updateEBookDataAction }) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleThemeChange = useCallback(
    (selectedTheme) => () => {
      const newTheme = theme.reader[selectedTheme];

      updateOptions({ palette: getPaletteMainColors(selectedTheme, newTheme) });
      updateEBookDataAction({ activeTheme: selectedTheme });
    },
    [],
  );

  return (
    <Box className={classes.colorThemeContainer}>
      <IconButton onClick={handleThemeChange(READER_THEMES.light)} className={commonClasses.iconBtn}>
        <Icons.ReaderThemeLight
          className={clsx(
            commonClasses.iconMd,
            classes.lightThemeIcon,
            activeTheme === READER_THEMES.light && classes.activeLightThemeIcon,
            activeTheme === READER_THEMES.sepia && classes.activeLightSepiaThemeIcon,
          )}
        />
      </IconButton>
      <IconButton onClick={handleThemeChange(READER_THEMES.sepia)} className={commonClasses.iconBtn}>
        <Icons.ReaderThemeSepia
          className={clsx(
            commonClasses.iconMd,
            classes.sepiaThemeIcon,
            activeTheme === READER_THEMES.sepia && classes.activeSepiaThemeIcon,
          )}
        />
      </IconButton>
      <IconButton onClick={handleThemeChange(READER_THEMES.dark)} className={commonClasses.iconBtn}>
        <Icons.ReaderThemeDark
          className={clsx(
            commonClasses.iconMd,
            classes.darkThemeIcon,
            activeTheme === READER_THEMES.dark && classes.activeDarkThemeIcon,
          )}
        />
      </IconButton>
    </Box>
  );
});

ThemePanel.propTypes = {
  ...ebookSettingsSharedProps,
};

export default ThemePanel;

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getMediaKey } from 'api/media';
import { isTouchDevice } from 'utils/screen';
import { CONTENT_WIDTH } from 'utils/themes/sizes';
import { ebookDataPropType, queuePropType } from 'utils/prop-types';
import useMediaQuery from 'hook/use-media-query';
import useWindowSize from 'hook/use-window-size';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FullScreenHeader from 'components/full-screen-components/header';
import Loader from 'components/loader';
import clsx from 'clsx';
import { useSwipeable } from 'react-swipeable';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { TransformWrapper, TransformComponent } from 'components/zoom-pan-pinch';
import useStyles from './pdf-reader.styles';
import EbookTimeline from '../components/ebook-timeline';
import ControlPanel from './components/control-panel';

const removeTextLayerOffset = () => {
  const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
  textLayers.forEach((layer) => {
    const { style } = layer;
    style.top = '0';
    style.left = '0';
    style.transform = '';
  });
};

const PDFReader = memo((props) => {
  const isTablet = useMediaQuery('tablet');

  const {
    queue,
    ebookData: { currentPage },
    activeIndex,
    resetPlayerAction,
    changeEbookReaderOpenAction,
    updateEBookDataAction,
  } = props;

  const { width, height } = useWindowSize();
  const isTouchableDevice = isTouchDevice();

  const activeQueueItem = queue[activeIndex];

  const pageRef = useRef();
  const documentRef = useRef();
  const transformRef = useRef();
  const [pdf, setPdf] = useState();
  const [file, setFile] = useState();
  const [scale, setScale] = useState(1);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isEBookLoaded, setIsEBookLoaded] = useState(false);
  const [initialCoordinates, setInitialCoordinates] = useState([0, 0]);
  const [isDistractionFreeMode, setIsDistractionFreeMode] = useState(false);

  const pageMaxHeight = useMemo(() => height - 210, [height]);
  const pageMaxWidth = useMemo(() => Math.min(CONTENT_WIDTH, width) - (isTablet ? 45 : 125), [width, isTablet]);

  const classes = useStyles({ pageMaxHeight, pageMaxWidth });

  const pageSizes = useMemo(() => {
    const sizes = { height: pageMaxHeight };

    if (pageMaxHeight > pageMaxWidth * 1.3) {
      sizes.width = pageMaxWidth;
    }

    return sizes;
  }, [isPageLoaded, pageMaxWidth, pageMaxHeight]);

  const goToPrevPage = useCallback(() => {
    const currentPageNumber = +pageRef?.current?.dataset?.pageNumber;
    const newPageNumber = currentPageNumber > 1 ? currentPageNumber - 1 : currentPageNumber;

    updateEBookDataAction({ currentPage: newPageNumber });
  }, []);

  const goToNextPage = useCallback(() => {
    const currentPageNumber = +pageRef?.current?.dataset?.pageNumber;
    const totalPagesNumber = +pageRef?.current?.dataset?.totalPages;
    const newPageNumber = currentPageNumber < totalPagesNumber ? currentPageNumber + 1 : currentPageNumber;

    updateEBookDataAction({ currentPage: newPageNumber });
  }, []);

  const closeEbookReader = useCallback(() => {
    changeEbookReaderOpenAction(false);
  }, []);

  const handleSwipeLeft = useCallback(
    (event) => {
      if (scale === 1 && event?.absX >= 100) {
        goToNextPage();
      }
    },
    [scale, goToNextPage],
  );

  const handleSwipeRight = useCallback(
    (event) => {
      if (scale === 1 && event?.absX >= 100) {
        goToPrevPage();
      }
    },
    [scale, goToPrevPage],
  );

  // Handlers for touchable devices
  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });

  // Keyboard listener for previousPage/nextPage navigation
  const handleKeydown = useCallback((event) => {
    switch (event.code) {
      case 'ArrowLeft': {
        goToPrevPage();
        break;
      }
      case 'ArrowRight': {
        goToNextPage();
        break;
      }
      default:
        break;
    }
  }, []);

  const loadEbookAsset = useCallback(() => {
    const { downloadUrl } = activeQueueItem;

    if (downloadUrl) {
      getMediaKey(downloadUrl)
        .then(({ key }) => {
          if (key) {
            setFile(key);
          }
        })
        .catch(() => {
          resetPlayerAction();
        });
    }
  }, [activeQueueItem]);

  const handleEBookItemClick = useCallback(({ pageNumber }) => {
    updateEBookDataAction({ currentPage: pageNumber });
  }, []);

  const handleDocumentLoadSuccess = useCallback(
    (pdfInstance) => {
      setPdf(pdfInstance);
      setIsEBookLoaded(true);
      pageRef.current.dataset.totalPages = pdfInstance.numPages;
      updateEBookDataAction({
        totalPages: pdfInstance.numPages,
        currentPage: activeQueueItem?.progress || currentPage,
      });
    },
    [currentPage, activeQueueItem],
  );

  const handlePageLoadSuccess = useCallback(() => {
    setIsPageLoaded(true);
    removeTextLayerOffset();
  }, []);

  // init reader
  useEffect(() => {
    loadEbookAsset();

    document.addEventListener('keydown', handleKeydown);
    // Clean up
    return () => {
      updateEBookDataAction({ totalPages: 0, currentPage: 1, skipHistoryUpdate: true });
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const customTextRenderer = useCallback(
    ({ str }) => <span className={clsx(!!str.trim() && classes.emptySelection)}>{str}</span>,
    [],
  );

  const renderControlPanel = useCallback(
    () => (isEBookLoaded ? <ControlPanel pdf={pdf} /> : null),
    [pdf, isEBookLoaded],
  );

  const handleTimelineChange = useCallback((newPageNumber) => {
    updateEBookDataAction({ currentPage: newPageNumber });
  }, []);

  const handlePanningStart = useCallback((_, event) => {
    if (isTablet) {
      setInitialCoordinates([event?.touches?.[0]?.clientX, event?.touches?.[0]?.clientY]);
    } else {
      setInitialCoordinates([event.x, event.y]);
    }
  }, []);

  const handlePageClick = useCallback(
    (_, event) => {
      const tagName = event?.target?.tagName ?? '';
      const isLink = tagName.toLowerCase() === 'a';

      if (!isLink) {
        if (isTablet) {
          setIsDistractionFreeMode((current) => !current);
        } else if (scale === 1) {
          transformRef.current?.zoomIn();
        } else {
          transformRef.current?.zoomOut();
        }
      }
    },
    [scale, isTablet],
  );

  const handlePanningStop = useCallback(
    (ref, event) => {
      const currentX = isTablet ? event?.touches?.[0]?.clientX : event?.x;
      const currentY = isTablet ? event?.touches?.[0]?.clientY : event?.y;
      const [initialX, initialY] = initialCoordinates;
      const isClick = currentY === initialY && currentX === initialX;

      if (isClick) {
        handlePageClick(null, event);
      }
    },
    [scale, isTablet, handlePageClick, initialCoordinates],
  );

  const handlePageDoubleClick = useCallback(() => {
    if (isTablet) {
      transformRef.current?.zoomIn();
    }
  }, [isTablet]);

  const transformHandlers = useMemo(
    () =>
      isTablet
        ? {
            onClick: handlePageClick,
            onDoubleClick: handlePageDoubleClick,
          }
        : {
            onPanningStop: handlePanningStop,
          },
    [isTablet, handlePageClick, handlePanningStop, handlePageDoubleClick],
  );

  return (
    <>
      <Fade in={!isDistractionFreeMode}>
        <Box>
          <FullScreenHeader
            height={99}
            item={activeQueueItem}
            onClose={closeEbookReader}
            nameClassName={classes.textThemeColor}
            creatorClassName={classes.textThemeColor}
            renderControlPanel={renderControlPanel}
          />
        </Box>
      </Fade>
      <Box className={classes.readerViewContainer}>
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.leftArrow)}>
            <IconButton onClick={goToPrevPage} className={classes.arrowIconBtn}>
              <ArrowBackIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.rightArrow)}>
            <IconButton onClick={goToNextPage} className={classes.arrowIconBtn}>
              <ArrowForwardIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        <Box {...handlers} className={classes.readerContainer}>
          <Document
            file={file}
            renderMode="svg"
            loading={<Loader />}
            inputRef={documentRef}
            externalLinkTarget="_blank"
            className={classes.readerView}
            onItemClick={handleEBookItemClick}
            onLoadSuccess={handleDocumentLoadSuccess}
          >
            <TransformWrapper
              ref={transformRef}
              {...transformHandlers}
              onPanningStart={handlePanningStart}
              panning={{ disabled: scale === 1 }}
              doubleClick={{ disabled: !isTablet }}
            >
              {({ state }) => {
                if (state?.scale >= 0) {
                  setScale(state.scale);
                }

                return (
                  <TransformComponent wrapperClass={classes.transformComponent}>
                    <Page
                      {...pageSizes}
                      inputRef={pageRef}
                      scale={state.scale}
                      loading={<Loader />}
                      pageNumber={currentPage}
                      onLoadSuccess={handlePageLoadSuccess}
                      customTextRenderer={customTextRenderer}
                    />
                  </TransformComponent>
                );
              }}
            </TransformWrapper>
          </Document>
        </Box>
      </Box>
      <EbookTimeline
        onChange={handleTimelineChange}
        textClassName={classes.textThemeColor}
        isDistractionFreeMode={isDistractionFreeMode}
      />
    </>
  );
});

PDFReader.propTypes = {
  queue: queuePropType,
  ebookData: ebookDataPropType,
  activeIndex: PropTypes.number,
  resetPlayerAction: PropTypes.func.isRequired,
  updateEBookDataAction: PropTypes.func.isRequired,
  changeEbookReaderOpenAction: PropTypes.func.isRequired,
};

export default PDFReader;

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { READER_THEMES } from 'utils/themes';
import { readerRefPropType } from 'utils/prop-types';
import useWindowSize from 'hook/use-window-size';
import { HighlightColor } from '@publizon/pubhub-reader';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getAnnotationColorOptions } from 'components/ebook-reader/ebook-reader.utils';
import clsx from 'clsx';
import useStyles from './annotation-modal.styles';

const AnnotationModal = memo((props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { readerRef, activeTheme } = props;
  const classes = useStyles({ activeTheme });

  const { width, height } = useWindowSize();

  const [selector, setSelector] = useState();
  const [textValue, setTextValue] = useState('');
  const [annotationEl, setAnnotationEl] = useState(null);
  const [highlightColorId, setHighlightColorId] = useState();

  const colorOptions = useMemo(() => getAnnotationColorOptions(theme, activeTheme), [theme, activeTheme]);

  const closeAnnotationPopover = useCallback(() => {
    setAnnotationEl(null);
    setSelector(undefined);
    setTextValue(undefined);
    setHighlightColorId(undefined);
  }, []);

  const handlePointerdown = useCallback((event) => {
    // Close annotation overlay on click anywhere in the reader document
    if (event.frameworkComponent === 'READER_DOCUMENT' || event.frameworkComponent === 'READER_VIEW') {
      closeAnnotationPopover();
    }
  }, []);

  const showAnnotationPopover = useCallback(
    (newSelector, selectionElements, newHighlightColorId = undefined, newNote = undefined) => {
      const overlayTargetElement = selectionElements[selectionElements.length - 1];

      setAnnotationEl(overlayTargetElement);

      setTextValue(newNote);
      setSelector(newSelector);
      setHighlightColorId(newHighlightColorId);
    },
    [],
  );

  // Text selection event
  const handleSelectionChanged = useCallback((event) => {
    showAnnotationPopover(event.selector, event.selectionContainers);
  }, []);

  // Annotation/highlight click
  const handleAnnotationClick = useCallback((event) => {
    showAnnotationPopover(event.selector, event.selectionContainers, event.highlightColorId, event.note);
  }, []);

  // Hide overlay panel on page change
  const handleReadingPositionChanged = useCallback(() => {
    closeAnnotationPopover();
  }, []);

  const setAnnotation = useCallback(
    (color) => () => {
      if (selector) {
        setHighlightColorId(color);
        readerRef.current.annotations.setAnnotation(selector, color, textValue);
      }
    },
    [selector, textValue],
  );

  const removeAnnotation = useCallback(() => {
    if (selector) {
      readerRef.current.annotations.removeAnnotation(selector);
      closeAnnotationPopover();
    }
  }, [selector]);

  const handleTextFieldChange = useCallback((event) => {
    setTextValue(event.target.value);
  }, []);

  const handleSaveAnnotation = useCallback(() => {
    // Only set highlight if there is any note text or a highlight color has been chosen
    const shouldHighlight = textValue || highlightColorId;

    if (!shouldHighlight) {
      return;
    }

    // Use yellow highlight color if there is note text but no color has been chosen
    if (!highlightColorId) {
      setHighlightColorId(HighlightColor.yellow);
      readerRef.current.annotations.setAnnotation(selector, HighlightColor.yellow, textValue);
    } else {
      readerRef.current.annotations.setAnnotation(selector, highlightColorId, textValue);
    }

    closeAnnotationPopover();
  }, [selector, textValue, highlightColorId]);

  useEffect(() => {
    readerRef.current.eventListeners.addListener('pointerdown', handlePointerdown);
    readerRef.current.eventListeners.addListener('annotationClick', handleAnnotationClick);
    readerRef.current.eventListeners.addListener('selectionChanged', handleSelectionChanged);
    readerRef.current.eventListeners.addListener('readingPositionChanged', handleReadingPositionChanged);

    return () => {
      readerRef.current.eventListeners.removeListener('pointerdown', handlePointerdown);
      readerRef.current.eventListeners.removeListener('annotationClick', handleAnnotationClick);
      readerRef.current.eventListeners.removeListener('selectionChanged', handleSelectionChanged);
      readerRef.current.eventListeners.removeListener('readingPositionChanged', handleReadingPositionChanged);
    };
  }, []);

  useEffect(() => {
    closeAnnotationPopover();
  }, [width, height]);

  return (
    <Popover
      disableScrollLock
      open={!!annotationEl}
      anchorEl={annotationEl}
      onClose={closeAnnotationPopover}
      classes={{
        paper: classes.popover,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box className={classes.colorOptionsContainer}>
        {colorOptions.map((color) => (
          <Button
            key={color.value}
            onClick={setAnnotation(color.readerHighlightColorId)}
            className={clsx(
              classes.colorOptionBtn,
              highlightColorId === color.readerHighlightColorId && classes.activeColorOptionBtn,
            )}
            style={{ color: color.value, backgroundColor: color.value }}
          />
        ))}
      </Box>
      <TextField
        multiline
        fullWidth
        rows={4}
        rowsMax={4}
        value={textValue}
        variant="outlined"
        className={classes.textField}
        onChange={handleTextFieldChange}
        inputProps={{ className: classes.textColor }}
      />
      <Box className={classes.actionButtonContainer}>
        {highlightColorId !== undefined && (
          <Button onClick={removeAnnotation} className={classes.textColor}>
            {t`ebook_annotations_button_remove`}
          </Button>
        )}
        <Button color="primary" className={classes.textColor} onClick={handleSaveAnnotation}>
          {t`ebook_annotations_button_save`}
        </Button>
      </Box>
    </Popover>
  );
});

AnnotationModal.propTypes = {
  readerRef: readerRefPropType,
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
};

export default AnnotationModal;

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  popover: {
    zIndex: 11,
  },
  popperPaper: {
    marginTop: 19,
    borderRadius: 13,
    '& .MuiList-root': {
      '& > .MuiListItem-root:first-child, & .MuiBox-root:first-child > .MuiListItem-root': {
        borderTopLeftRadius: 13,
        borderTopRightRadius: 13,
      },
      '& > .MuiListItem-root:last-child, & .MuiBox-root:last-child > .MuiListItem-root': {
        borderBottomLeftRadius: 13,
        borderBottomRightRadius: 13,
      },
    },
  },
}));

export default useStyles;

export const getTOCItems = () => {
  const tocItems = [];
  const allTOCAllItemsEl = document.querySelectorAll('#readium-toc-body a');

  allTOCAllItemsEl.forEach((tocItem) => {
    const item = {
      title: tocItem.textContent,
      href: tocItem.getAttribute('href'),
    };

    tocItems.push(item);
  });

  return tocItems;
};

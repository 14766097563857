import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Icons from 'components/icons';
import ASSET from 'constants/asset-types';
import useStyles from './content-icon.styles';

const getIconWithStyles = (option, contentType, classes) =>
  ({
    ebook: {
      play: [Icons.Glasses, ''],
      info: [Icons.Glasses, ''],
      playing: [Icons.VolumeUp, ''],
    },
    audio: {
      play: [Icons.Play, classes.audioPlayIcon],
      info: [Icons.Headphones, classes.audioInfoIcon],
      playing: [Icons.VolumeUp, ''],
    },
    video: {
      play: [Icons.Play, classes.videoPlayIcon],
      info: [Icons.Slideshow, ''],
      playing: [Icons.VolumeUp, ''],
    },
  }[contentType][option]);

const ContentIcon = forwardRef(({ type, option, className, contentType, iconClassName, ...restProps }, ref) => {
  const classes = useStyles();

  const isButton = type === 'button';
  const [Icon, additionalIconClassName] = getIconWithStyles(option, contentType, classes);

  return isButton ? (
    <IconButton ref={ref} className={clsx(classes.button, className)} {...restProps}>
      <Icon className={clsx(classes.icon, additionalIconClassName, iconClassName)} />
    </IconButton>
  ) : (
    <Icon className={clsx(classes.icon, additionalIconClassName, iconClassName)} {...restProps} />
  );
});

ContentIcon.propTypes = {
  type: PropTypes.oneOf(['button', 'icon']),
  option: PropTypes.oneOf(['play', 'info']),
  contentType: PropTypes.oneOf(Object.keys(ASSET.contentType)),
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

ContentIcon.defaultProps = {
  type: 'icon',
  option: 'info',
  contentType: ASSET.contentType.audio,
  className: '',
  iconClassName: '',
};

export default ContentIcon;

import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getTracks } from 'api/tracks';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { getFullTrackName } from 'utils/formatters';
import { queuePropType } from 'utils/prop-types';
import { QueueListIcon } from 'components/icons';
import ControlButton from 'components/common/control-button';
import PopperOverlay from 'components/common/popper-overlay';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useStyles from './queue-option.styles';

const getItemKey = (index, { queue }) => queue[index]?.trackId ?? index;

const QueueItem = memo(({ index, style, data }) => {
  const { queue, classes, activeIndex, dividerClassName, menuListItemClassName, handlePlaylistItemClick } = data;

  const fullTrackName = useMemo(() => getFullTrackName(queue[index]), [queue, index]);

  return (
    <div style={style}>
      {index !== 0 && <Divider className={dividerClassName} />}
      <MenuItem
        className={clsx(classes.queueMenuItem, menuListItemClassName)}
        title={fullTrackName}
        onClick={handlePlaylistItemClick(index)}
      >
        <Typography
          noWrap
          component="span"
          className={clsx(classes.queueMenuItemText, index === activeIndex && classes.activeQueueMenuItem)}
        >
          {fullTrackName}
        </Typography>
      </MenuItem>
    </div>
  );
});

QueueItem.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    queue: queuePropType,
    classes: PropTypes.shape({
      queueMenuItem: PropTypes.string,
      queueMenuItemText: PropTypes.string,
      activeQueueMenuItem: PropTypes.string,
    }).isRequired,
    dividerClassName: PropTypes.string,
    menuListItemClassName: PropTypes.string,
    activeIndex: PropTypes.number,
    handlePlaylistItemClick: PropTypes.func,
  }).isRequired,
};

const QueueOption = memo((props) => {
  const {
    placement,
    className,
    queue,
    iconClassName,
    paperClassName,
    activeIndex,
    dividerClassName,
    updateQueueAction,
    menuListClassName,
    menuListItemClassName,
    changeActiveTrackIndexAction,
  } = props;

  const classes = useStyles({ height: Math.min(240, queue.length * 37) });

  const queueMenuBtnRef = useRef(null);
  const [isQueueMenuOpened, setIsQueueMenuOpened] = useState(false);

  const togglePlaylistMenu = useCallback(() => {
    setIsQueueMenuOpened((currentFlag) => !currentFlag);
  }, []);

  const handleClosePlaylistMenu = useCallback((event) => {
    if (queueMenuBtnRef.current && queueMenuBtnRef.current.contains(event?.target)) {
      return;
    }

    setIsQueueMenuOpened(false);
  }, []);

  const handlePlaylistItemClick = useCallback(
    (value) => () => {
      changeActiveTrackIndexAction(value);
    },
    [],
  );

  const isItemLoaded = useCallback((index) => !!queue[index]?.id, [queue]);

  const loadMoreItems = useCallback(
    (startIndex, stopIndex) => {
      const trackIdsToLoad = queue
        .slice(startIndex, stopIndex + 1)
        .reduce((acc, item) => ({ ...acc, [`${item.trackId}_${item.assetId}`]: item.trackId }), {});

      return getTracks(trackIdsToLoad).then((loadedItems) => {
        const mappedLoadedItems = loadedItems.reduce((acc, item) => ({ ...acc, [item.trackId]: item }), {});
        const mappedLoadedItemsWithAssets = loadedItems.reduce(
          (acc, item) => ({ ...acc, [`${item.trackId}_${item.assetId}`]: item }),
          {},
        );

        updateQueueAction(
          queue.map((currentItem) => {
            const mappedItem = currentItem.assetId
              ? mappedLoadedItemsWithAssets[`${currentItem.trackId}_${currentItem.assetId}`]
              : mappedLoadedItems[currentItem.trackId];

            return mappedItem ? { ...currentItem, ...mappedItem } : currentItem;
          }),
        );
      });
    },
    [queue, updateQueueAction],
  );

  return (
    <>
      <ControlButton
        icon={QueueListIcon}
        className={className}
        buttonRef={queueMenuBtnRef}
        onClick={togglePlaylistMenu}
        iconClassName={clsx(classes.icon, iconClassName)}
      />
      <PopperOverlay
        isOpen={isQueueMenuOpened}
        placement={placement}
        onClose={handleClosePlaylistMenu}
        anchor={queueMenuBtnRef.current}
        popperClassName={clsx(classes.paper, paperClassName)}
      >
        <MenuList className={clsx(classes.queueMenu, menuListClassName)}>
          <InfiniteLoader
            threshold={5}
            minimumBatchSize={10}
            itemCount={queue.length}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    ref={ref}
                    width={width}
                    height={height}
                    layout="vertical"
                    itemKey={getItemKey}
                    itemData={{
                      queue,
                      classes,
                      activeIndex,
                      dividerClassName,
                      menuListItemClassName,
                      handlePlaylistItemClick,
                    }}
                    itemCount={queue.length}
                    className={classes.queueMenuItem}
                    onItemsRendered={onItemsRendered}
                    itemSize={36}
                  >
                    {QueueItem}
                  </List>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </MenuList>
      </PopperOverlay>
    </>
  );
});

QueueOption.propTypes = {
  placement: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  paperClassName: PropTypes.string,
  dividerClassName: PropTypes.string,
  menuListClassName: PropTypes.string,
  menuListItemClassName: PropTypes.string,
  queue: queuePropType,
  activeIndex: PropTypes.number,
  updateQueueAction: PropTypes.func.isRequired,
  changeActiveTrackIndexAction: PropTypes.func.isRequired,
};

QueueOption.defaultProps = {
  className: '',
  iconClassName: '',
  paperClassName: '',
};

export default QueueOption;

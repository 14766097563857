/* eslint-disable import/no-cycle */
import { push } from 'connected-react-router';
import { createRoutine } from 'redux-saga-routines';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as authServices from 'api/auth';
import { removeAllPersistentData } from 'utils/localstorage';
import { parseFirebaseError } from 'utils/auth/firebase';
import ROUTES, { getTabPageRoute } from 'utils/routes';
import { getConfig } from '../app';

export const signupRoutine = createRoutine('SIGNUP');
export const loginRoutine = createRoutine('LOGIN');
export const passwordResetRoutine = createRoutine('PASSWORD_RESET');
export const logoutRoutine = createRoutine('LOGOUT');
export const loginOAuthRoutine = createRoutine('LOGIN_OAUTH');
export const resetAuthErrorRoutine = createRoutine('RESET_AUTH_ERROR');

export const initialState = Object.freeze({
  isLoading: false,
  error: null,
});

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case signupRoutine.REQUEST:
    case loginRoutine.REQUEST:
    case passwordResetRoutine.REQUEST:
    case logoutRoutine.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case signupRoutine.SUCCESS:
    case loginRoutine.SUCCESS:
    case passwordResetRoutine.SUCCESS:
    case loginOAuthRoutine.SUCCESS:
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case signupRoutine.FAILURE:
    case loginRoutine.FAILURE:
    case passwordResetRoutine.FAILURE:
    case loginOAuthRoutine.FAILURE:
    case logoutRoutine.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case resetAuthErrorRoutine.REQUEST:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export const getUserId = ({ firebase }) => firebase?.auth?.uid;

export function* signupSaga({ payload: formValues }) {
  try {
    const userCredential = yield call(authServices.signUp, formValues);

    userCredential.user.sendEmailVerification();

    yield put(signupRoutine.success());

    const config = yield select(getConfig);
    const firstContentTab = config?.tabs?.[0];
    const routeToRedirect = getTabPageRoute(firstContentTab.target, firstContentTab.targetId);

    yield put(push(routeToRedirect));
  } catch (err) {
    const { message } = parseFirebaseError(err);

    yield put(signupRoutine.failure(message));
  }
}

export function* loginSaga({ payload: formValues }) {
  try {
    yield call(authServices.login, formValues);

    yield put(loginRoutine.success());

    const config = yield select(getConfig);
    const firstContentTab = config?.tabs?.[0];
    const routeToRedirect = getTabPageRoute(firstContentTab.target, firstContentTab.targetId);

    yield put(push(routeToRedirect));
  } catch (err) {
    const { message } = parseFirebaseError(err);

    yield put(loginRoutine.failure(message));
  }
}

export function* passwordResetSaga({ payload: formValues }) {
  try {
    yield call(authServices.resetPassword, formValues);

    yield put(passwordResetRoutine.success());

    // TODO: using `failure` to pass a success message is a bit hacky
    yield put(passwordResetRoutine.failure('auth_firebase_alert_message_resetpasswordsuccess'));
  } catch (err) {
    const { message } = parseFirebaseError(err);

    yield put(passwordResetRoutine.failure(message));
  }
}

export function* logoutSaga() {
  try {
    yield call(authServices.logout);

    yield put(logoutRoutine.success());
    removeAllPersistentData();
    yield put(push(ROUTES.root));
  } catch (err) {
    const { message } = parseFirebaseError(err);

    yield put(logoutRoutine.failure(message));
  }
}

export function* loginOAuthSaga({ payload: token }) {
  try {
    yield call(authServices.loginWithCustomToken, token);

    yield put(loginOAuthRoutine.success());

    const config = yield select(getConfig);
    const firstContentTab = config?.tabs?.[0];
    const routeToRedirect = getTabPageRoute(firstContentTab.target, firstContentTab.targetId);

    yield put(push(routeToRedirect));
  } catch (err) {
    const { message } = parseFirebaseError(err);

    yield put(loginOAuthRoutine.failure(message));
  }
}

export function* watchAuthSagas() {
  yield takeLatest(signupRoutine.REQUEST, signupSaga);
  yield takeLatest(loginRoutine.REQUEST, loginSaga);
  yield takeLatest(passwordResetRoutine.REQUEST, passwordResetSaga);
  yield takeLatest(logoutRoutine.REQUEST, logoutSaga);
  yield takeLatest(loginOAuthRoutine.REQUEST, loginOAuthSaga);
}

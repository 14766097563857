import api from 'utils/api';
import { BASE_API_URL } from 'api/constants';
import { mapTrackToAsset } from 'api/utils';

export const searchTracks = async (query, permissions) =>
  api
    .get(
      `${BASE_API_URL}/search/`,
      {params: {query: query, permissions: permissions.join()}},
    )
    .then((response) => response.data.results.tracks.map((track) => mapTrackToAsset(track)));

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  trackInfo: {
    flexGrow: 1,
    paddingTop: 28,
    paddingLeft: 18,
    maxWidth: 420,
  },
  trackCreator: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2,
  },
  trackNameContainer: {
    display: 'flex',
  },
  trackNameText: {
    fontSize: 28,
    letterSpacing: 0,
    lineHeight: '33px',
    maxWidth: 390,
    color: theme.palette.colors.backdropOnColor,
    fontFamily: 'ARSMaquetteProMedium',
  },
  trackCreatorImage: {
    minWidth: 22,
    maxWidth: 22,
    height: 22,
    marginRight: 8,
    display: 'inline-block',
    borderRadius: 1.1,
  },
  trackCreatorText: {
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '22px',
    fontFamily: 'Roboto Regular',
    color: theme.palette.colors.backdropOnColor,
  },
}));

export default useStyles;

import { HighlightColor } from '@publizon/pubhub-reader';

export const getAnnotationColorOptions = (theme, activeTheme) =>
  Object.entries(theme.reader[activeTheme].highlightColors).map(([name, value]) => ({
    name,
    value,
    readerHighlightColorId: HighlightColor[name],
  }));

export const NAVIGATION_TABS = Object.freeze({
  chapters: 'chapters',
  bookmarks: 'bookmarks',
  annotation: 'annotation',
});

export const getTabA11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export const getNavigationTabEmptyMessage = (value) =>
  ({
    [NAVIGATION_TABS.chapters]: {
      title: 'ebook_no_toc_title',
      subtitle: 'ebook_no_toc_subtitle',
    },
    [NAVIGATION_TABS.bookmarks]: {
      title: 'ebook_no_bookmarks_title',
      subtitle: 'ebook_no_bookmarks_subtitle',
    },
    [NAVIGATION_TABS.annotation]: {
      title: 'ebook_no_annotations_title',
      subtitle: 'ebook_no_annotations_subtitle',
    },
  }[value]);

export const NAVIGATION_TAB_OPTIONS = Object.freeze({
  [NAVIGATION_TABS.chapters]: {
    value: NAVIGATION_TABS.chapters,
    label: 'ebook_navigation_section_toc',
    ...getTabA11yProps(NAVIGATION_TABS.chapters),
  },
  [NAVIGATION_TABS.bookmarks]: {
    value: NAVIGATION_TABS.bookmarks,
    label: 'ebook_navigation_section_bookmarks',
    ...getTabA11yProps(NAVIGATION_TABS.bookmarks),
  },
  [NAVIGATION_TABS.annotation]: {
    value: NAVIGATION_TABS.annotation,
    label: 'ebook_navigation_section_annotations',
    ...getTabA11yProps(NAVIGATION_TABS.annotation),
  },
});

export const getNavigationTabOptions = (tabs) => tabs.map((tab) => NAVIGATION_TAB_OPTIONS[tab]);

import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ebookDataPropType } from 'utils/prop-types';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import useStyles from './ebook-timeline.styles';

const EBookTimeLine = memo((props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    onChange,
    sliderProps,
    isMiniPlayer,
    textClassName,
    progressText,
    isDistractionFreeMode,
    ebookData: { totalPages, currentPage },
  } = props;

  const [value, setValue] = useState(currentPage);

  const handleTimelineChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  const handleTimelineChangeCommitted = useCallback(
    (_, newValue) => {
      onChange?.(newValue);
    },
    [onChange],
  );

  const editableProps = useMemo(
    () =>
      isMiniPlayer
        ? {}
        : {
            onChange: handleTimelineChange,
            onChangeCommitted: handleTimelineChangeCommitted,
            valueLabelDisplay: 'auto',
          },
    [isMiniPlayer, handleTimelineChange, handleTimelineChangeCommitted],
  );

  useEffect(() => {
    setValue((current) => (current !== currentPage ? currentPage : current));
  }, [currentPage]);

  return totalPages ? (
    <Box className={clsx(!isMiniPlayer && classes.timelineContainer)}>
      <Fade in={!isDistractionFreeMode}>
        <Box>
          <Slider
            min={1}
            max={totalPages}
            value={value}
            color="primary"
            aria-label="page-indicator"
            className={clsx(classes.timeline, isMiniPlayer && classes.timelineMiniPlayer)}
            classes={{
              rail: classes.timelineRail,
              thumb: clsx(classes.timelineIndicator, isMiniPlayer && classes.timelineIndicatorViewOnly),
            }}
            {...editableProps}
            {...sliderProps}
          />
          {!isMiniPlayer && (
            <Typography component="span" className={clsx(classes.currentPageNumber, textClassName)}>
              {progressText || t('generic_progress', { arg1: currentPage, arg2: totalPages })}
            </Typography>
          )}
        </Box>
      </Fade>
      <Fade in={isDistractionFreeMode}>
        <Box>
          <Slider
            min={0}
            max={totalPages}
            value={value}
            color="primary"
            aria-label="page-indicator"
            className={classes.timelineDistractionFreeMode}
            classes={{
              rail: classes.timelineRailDistractionFreeMode,
              thumb: classes.timelineIndicatorViewOnly,
            }}
          />
        </Box>
      </Fade>
    </Box>
  ) : null;
});

EBookTimeLine.propTypes = {
  isViewonly: PropTypes.bool,
  ebookData: ebookDataPropType,
  textClassName: PropTypes.string,
  progressText: PropTypes.string,
  isMiniPlayer: PropTypes.bool,
  onChange: PropTypes.func,
  sliderProps: PropTypes.shape({}),
  isDistractionFreeMode: PropTypes.bool,
};

EBookTimeLine.defaultProps = {
  isViewonly: false,
  textClassName: '',
  isMiniPlayer: false,
  sliderProps: {},
  isDistractionFreeMode: false,
};

export default EBookTimeLine;

import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import PopperOverlay from 'components/common/popper-overlay';
import { getTabPageRoute } from 'utils/routes';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import useStyles from './nav-item-dropdown.styles';

const NavItemDropdown = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { label, items } = props;

  const isActive = useMemo(() => items.some((item) => location?.pathname?.includes?.(item?.target)), [items]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuAnchorRef = useRef(null);

  const handleToggle = useCallback(() => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  }, [setIsMenuOpen]);

  const handleCloseMenu = useCallback(
    (event) => {
      if (menuAnchorRef.current && menuAnchorRef.current.contains(event?.target)) {
        return;
      }

      setIsMenuOpen(false);
    },
    [setIsMenuOpen],
  );

  const handleItemClick = useCallback(
    (itemRoute) => () => {
      history.push(itemRoute);
      setIsMenuOpen(false);
    },
    [],
  );

  return (
    <>
      <Typography
        ref={menuAnchorRef}
        aria-haspopup="true"
        onClick={handleToggle}
        className={clsx(classes.navItem, isActive && classes.activeNavItem)}
        aria-controls={isMenuOpen ? 'menu-list-grow' : undefined}
      >
        {t(label)}
      </Typography>
      <PopperOverlay
        isOpen={isMenuOpen}
        placement="bottom-start"
        onClose={handleCloseMenu}
        anchor={menuAnchorRef.current}
        popperClassName={classes.paper}
      >
        <MenuList className={classes.menuList}>
          {items.map((item, index) => {
            const itemRoute = getTabPageRoute(item.target, item.target_id);

            return (
              <>
                {index !== 0 && <Divider />}
                <MenuItem onClick={handleItemClick(itemRoute)} className={classes.menuItem}>
                  <Typography
                    noWrap
                    component="span"
                    className={clsx(
                      classes.menuItemText,
                      !!location?.pathname?.includes?.(itemRoute) && classes.activeMenuItemText,
                    )}
                  >
                    {t(item.label)}
                  </Typography>
                </MenuItem>
              </>
            );
          })}
        </MenuList>
      </PopperOverlay>
    </>
  );
};

NavItemDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.string,
      target_id: PropTypes.string,
    }),
  ),
};

export default NavItemDropdown;

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  readerViewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: 'calc(100% - 120px)',
    margin: '0 auto',
    maxHeight: ({ pageMaxHeight }) => pageMaxHeight,
    maxWidth: ({ pageMaxWidth }) => pageMaxWidth,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 'calc(100% - 40px)',
    },
  },
  hide: {
    display: 'none',
  },
  readerContainer: {
    maxHeight: ({ pageMaxHeight }) => pageMaxHeight,
    maxWidth: ({ pageMaxWidth }) => pageMaxWidth,
  },
  emptySelection: {
    opacity: 0,
  },
  readerView: {
    maxHeight: ({ pageMaxHeight }) => pageMaxHeight,
    maxWidth: ({ pageMaxWidth }) => pageMaxWidth,
    overflow: 'hidden',
    '& #reading-area': {
      boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 20px',
    },
    '& #readium-page-btns': {
      display: 'none',
    },
  },
  transformComponent: {
    userSelect: 'unset',
  },
  arrowBg: {
    width: 48,
    height: 290,
    opacity: 0.5,
    display: 'flex',
    borderRadius: 6,
    top: 'calc(50% - 145px)',
    position: 'absolute',
    transition: 'opacity 0.3s',
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundDark,
  },
  arrowIconBtn: {},
  leftArrow: {
    left: '-54px',
  },
  rightArrow: {
    right: '-54px',
  },
  icon: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  textThemeColor: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  tooltip: {
    fontSize: 12,
    marginBottom: 9,
    color: theme.palette.colors.primaryOnColor,
    backgroundColor: theme.palette.colors.primaryColor,
  },
}));

export default useStyles;

import { v4 } from 'uuid';

export const findByUserId = async ({ collection, userId }) => collection.find({ _user_id: userId });

export const updateOne = async ({ collection, query, data, options = { upsert: true } }) =>
  collection.updateOne(
    query,
    {
      $set: data,
      $setOnInsert: {
        _id: v4(),
      },
    },
    options,
  );

export const removeOne = async ({ collection, query }) => collection.deleteOne(query);

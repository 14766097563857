import { connect } from 'react-redux';
import { logoutRoutine } from 'redux-state/ducks/auth';
import HeaderMobile from './header.mobile.component';

const mapStateToProps = ({ app, firebase }) => ({
  tabs: app?.config?.tabs ?? [],
  appName: app?.config?.appName,
  authType: app?.config?.authType,
  brandImage: app?.config?.brandImage,
  displayName: firebase?.auth?.displayName || firebase?.auth?.email || '-',
  firebaseFunctionLocation: app?.config?.firebaseFunctionLocation,
});

const mapDispatchToProps = {
  logoutAction: logoutRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);

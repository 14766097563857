import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { READER_THEMES } from 'utils/themes';
import Box from '@material-ui/core/Box';
import FullScreenButton from 'components/full-screen-components/full-screen-button';
import MoreMenuButton from 'components/full-screen-components/more-menu-button';
import SettingsMenu from './settings-menu';
import NavigationMenu from './navigation-menu';
import useStyles from './control-panel.styles';

const ControlPanel = memo(({ tocItems, activeTheme }) => {
  const classes = useStyles({ activeTheme });

  return (
    <Box className={classes.controlPanelContainer}>
      <FullScreenButton className={classes.controlButton} iconClassName={classes.controlIcon} />
      <SettingsMenu
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
      <NavigationMenu
        tocItems={tocItems}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
      <MoreMenuButton
        className={classes.moreMenu}
        itemClassName={classes.moreMenuItem}
        textClassName={classes.moreMenuText}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
    </Box>
  );
});

ControlPanel.propTypes = {
  tocItems: PropTypes.arrayOf({
    href: PropTypes.string,
    title: PropTypes.string,
  }),
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
};

export default ControlPanel;

import React, { memo, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isiOS } from 'utils/user-agent';
import { READER_THEMES } from 'utils/themes';
import useMediaQuery from 'hook/use-media-query';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Icons from 'components/icons';
import {
  NAVIGATION_TABS,
  getNavigationTabOptions,
  getNavigationTabEmptyMessage,
} from 'components/ebook-reader/ebook-reader.utils';
import ControlButton from 'components/common/control-button';
import useStyles from './navigation-menu.styles';

const TAB_OPTIONS = getNavigationTabOptions([NAVIGATION_TABS.chapters]);

const TabPanel = memo((props) => {
  const classes = useStyles();
  const { children, value, index } = props;

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const NavigationMenu = memo((props) => {
  const { t } = useTranslation();
  const { tocItems, activeTheme, closeNavigationMenu } = props;
  const classes = useStyles({ activeTheme });

  const [activeTab, setActiveTab] = useState(NAVIGATION_TABS.chapters);

  const handleNavItemClick = useCallback(
    (href) => () => {
      const tocUrl = window?.READIUM?.reader?.tocUrl;

      window?.READIUM?.reader?.openContentUrl(href, tocUrl, undefined);
      closeNavigationMenu();
    },
    [],
  );

  const handleTabChange = useCallback((_, value) => {
    setActiveTab(value);
  }, []);

  const TAB_TO_VALUES = useMemo(
    () => ({
      [NAVIGATION_TABS.chapters]: tocItems,
    }),
    [tocItems],
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.tabHeader}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          variant="fullWidth"
          value={activeTab}
          onChange={handleTabChange}
        >
          {TAB_OPTIONS.map(({ value, label, ...restProps }) => (
            <Tab key={value} value={value} label={t(label)} {...restProps} />
          ))}
        </Tabs>
      </Box>
      {TAB_OPTIONS.map(({ value }) => {
        const items = TAB_TO_VALUES[value];
        const emptyMessage = !items?.length ? getNavigationTabEmptyMessage(value) : null;

        return (
          <TabPanel key={value} value={activeTab} index={value}>
            {emptyMessage && (
              <>
                <Typography noWrap className={classes.emptyMessageTitle}>
                  {t(emptyMessage.title)}
                </Typography>
                <Typography noWrap className={classes.emptyMessageSubtitle}>
                  {t(emptyMessage.subtitle)}
                </Typography>
              </>
            )}
            {!!items?.length && (
              <List>
                {TAB_TO_VALUES[value].map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    disablePadding
                    className={classes.navItem}
                    onClick={handleNavItemClick(item.href)}
                  >
                    <Typography noWrap className={classes.navItemTitle}>
                      {item.title}
                    </Typography>
                    {item.pageNumber && (
                      <Typography noWrap className={classes.navItemSubTitle}>
                        {t('generic_format_page', { arg: item.pageNumber })}
                      </Typography>
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </TabPanel>
        );
      })}
    </Box>
  );
});

NavigationMenu.propTypes = {
  tocItems: PropTypes.arrayOf({
    href: PropTypes.string,
    title: PropTypes.string,
  }),
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
  closeNavigationMenu: PropTypes.func.isRequired,
};

const NavigationMenuWrapper = memo((props) => {
  const classes = useStyles();
  const isIOSDevice = isiOS();
  const isTablet = useMediaQuery('tablet');
  const { controlBtnProps, ...navigationMenuProps } = props;

  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const toggleDrawer = useCallback(
    (openState) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setIsNavigationOpen((currentState) => openState ?? !currentState);
    },
    [],
  );

  const closeNavigationMenu = useCallback(() => setIsNavigationOpen(false), []);

  return (
    <>
      <ControlButton onClick={toggleDrawer()} icon={Icons.ReaderTOC} {...controlBtnProps} />
      <SwipeableDrawer
        disableScrollLock
        disableSwipeToOpen
        disableDiscovery={isIOSDevice}
        disableBackdropTransition={!isIOSDevice}
        anchor={isTablet ? 'bottom' : 'right'}
        open={isNavigationOpen}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <NavigationMenu closeNavigationMenu={closeNavigationMenu} {...navigationMenuProps} />
      </SwipeableDrawer>
    </>
  );
});

NavigationMenuWrapper.propTypes = {
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
};

export default NavigationMenuWrapper;

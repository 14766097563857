import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  trackInfoContainer: {
    display: 'flex',
    flex: 1,
    cursor: 'pointer',
    paddingTop: 9,
    paddingBottom: 9,
    maxWidth: 'calc(100% / 3)',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: 0,
      paddingTop: 2,
      maxWidth: 'calc(100% - 60px)',
    },
  },
  trackImage: {
    width: 36,
    height: 36,
    marginRight: 11,
    borderRadius: 6,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '100%',
      height: '100%',
      borderRadius: 0,
    },
  },
  trackInfo: {
    width: 'calc(100% - 60px)',
    paddingTop: 4,
    height: '100%',
    alignSelf: 'start',
    cursor: 'pointer',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingLeft: 4,
    },
  },
  trackCreator: {
    fontSize: 11,
    marginBottom: 2,
    lineHeight: '12px',
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.surfaceMutedColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingTop: 6,
      paddingBottom: 2,
    },
  },
  trackName: {
    fontSize: 11,
    fontWeight: 500,
    fontFamily: 'ARSMaquetteProMedium',
    color: theme.palette.colors.surfaceOnColor,
  },
  playerIcon: {
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  optionIcon: {
    width: 17,
    height: 17,
  },
  trackOptions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandIconBtn: {
    width: 36,
    height: 36,
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'opacity 0.1s',
  },
  trackImageContainer: {
    position: 'relative',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 52,
      height: 52,
    },
  },
  expandIcon: {
    width: 22,
    height: 22,
  },
  onTrackInfoHover: {
    opacity: 1,
    borderRadius: 6,
    padding: 0,
    backdropFilter: 'blur(20px)',
  },
  volumePanel: {
    width: 50,
    height: 150,
    zIndex: 9,
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 0',
  },
}));

export default useStyles;

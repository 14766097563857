import React, { memo } from 'react';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import PlaybackRateOption from 'components/player-components/playback-rate-option';
import FullScreenButton from 'components/full-screen-components/full-screen-button';
import MoreMenuButton from 'components/full-screen-components/more-menu-button';
import QueueOption from 'components/player-components/queue-option';
import VolumeOption from 'components/player-components/volume-option';
import useStyles from './control-panel.styles';

const ControlPanel = memo((props) => {
  const classes = useStyles();
  const { playerRef } = props;

  return (
    <Box className={classes.controlPanelContainer}>
      <FullScreenButton />
      <QueueOption placement="bottom-end" className={classes.controlButton} iconClassName={classes.controlIcon} />
      <VolumeOption
        placement="bottom-end"
        playerRef={playerRef}
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
      />
      <PlaybackRateOption
        placement="bottom-end"
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
      />
      <MoreMenuButton
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
    </Box>
  );
});

ControlPanel.propTypes = {
  playerRef: playerSharedPropTypes.playerRef,
};

export default ControlPanel;

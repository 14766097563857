import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTabPageRoute } from 'utils/routes';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import NavLink from '../../components/nav-link';
import useStyles from './collapse-nav-item.styles';

const CollapseNavItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { label, items, onClick } = props;

  const isActive = useMemo(() => items.some((item) => location?.pathname?.includes?.(item?.target)), [items]);

  const [isOpen, setIsOpen] = useState(true);

  const handleClick = useCallback(() => {
    setIsOpen((state) => !state);
  }, []);

  const closeMenu = useCallback(() => {
    onClick();
    setIsOpen(false);
  }, [onClick]);

  return (
    <>
      <ListItem className={clsx(classes.burgerMenuItem, isOpen && classes.openBurgerMenuItem)} onClick={handleClick}>
        <Typography
          component="span"
          className={clsx(classes.burgerMenuItemText, isActive && classes.activeBurgerItemText)}
        >
          {t(label)}
          {isOpen ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
        </Typography>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item) => (
            <ListItem key={item.label} className={classes.collapsedMenuItem}>
              <NavLink href={getTabPageRoute(item.target, item.target_id)} onClick={closeMenu}>
                {t(item.label)}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

CollapseNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.string,
      target_id: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func.isRequired,
};

export default CollapseNavItem;

import { connect } from 'react-redux';
import { initRealmDataRoutine, updateUserDataRoutine, updateUserSubscriptionRoutine } from 'redux-state/ducks/app';
import AppHandlers from './app-handlers.component';

const mapStateToProps = ({ firebase }) => ({
  userId: firebase?.auth?.uid,
});

const mapDispatchToProps = {
  initRealmDataAction: initRealmDataRoutine.request,
  updateUserDataAction: updateUserDataRoutine.request,
  updateUserSubscriptionAction: updateUserSubscriptionRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHandlers);

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import logger from 'utils/logger';
import { getMediaKey } from 'api/media';
import { isTouchDevice } from 'utils/screen';
import { CONTENT_WIDTH } from 'utils/themes/sizes';
import { ebookDataPropType, queuePropType } from 'utils/prop-types';
import useMediaQuery from 'hook/use-media-query';
import useWindowSize from 'hook/use-window-size';
import useScript from 'hook/use-script';
import Loader from 'components/loader';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FullScreenHeader from 'components/full-screen-components/header';
import clsx from 'clsx';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import useStyles from './epub-reader.styles';
import EbookTimeline from '../components/ebook-timeline';
import ControlPanel from './components/control-panel';
import useEpubReaderSwipes from './epub-reader.hooks';
import { getTOCItems } from './epub-reader.utils';

const ValueLabelComponent = memo(({ value, classes, children, open, tocItems, timelineItems }) => (
  <Tooltip
    open={open}
    placement="top"
    enterTouchDelay={0}
    classes={{ tooltipPlacementTop: classes.tooltip }}
    title={tocItems.find((item) => item.href === timelineItems[value - 1].href)?.title ?? '-'}
  >
    {children}
  </Tooltip>
));

ValueLabelComponent.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.number,
  classes: PropTypes.shape({
    tooltip: PropTypes.string,
  }),
  tocItems: PropTypes.arrayOf({
    href: PropTypes.string,
    title: PropTypes.string,
  }),
  timelineItems: PropTypes.arrayOf({
    href: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

const EPUB_READER_FOLDER = `${process.env.PUBLIC_URL}/epub-reader`;
const CSS_READER_PATH = `${EPUB_READER_FOLDER}/css/readium-all.css`;
const FONT_READER_SCRIPT_PATH = `${EPUB_READER_FOLDER}/font-faces/fonts.js`;
const MAIN_READER_SCRIPT_PATH = `${EPUB_READER_FOLDER}/scripts/readium-js-viewer_all_LITE.js`;

const EpubReader = memo((props) => {
  const isTablet = useMediaQuery('tablet');

  const {
    queue,
    activeIndex,
    ebookData: { activeTheme, currentPage },
    resetPlayerAction,
    changeEbookReaderOpenAction,
    updateEBookDataAction,
  } = props;

  useScript(FONT_READER_SCRIPT_PATH);
  useScript(MAIN_READER_SCRIPT_PATH);

  const { width, height } = useWindowSize();
  const isTouchableDevice = isTouchDevice();

  const activeQueueItem = queue[activeIndex];

  const prevChapterRef = useRef({});
  const [tocItems, setTocItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timelineItems, setTimelineItems] = useState([]);
  const [isEBookLoaded, setIsEBookLoaded] = useState(false);
  const [isDistractionFreeMode, setIsDistractionFreeMode] = useState(false);

  const pageMaxHeight = useMemo(() => height - 210, [height]);
  const pageMaxWidth = useMemo(() => Math.min(CONTENT_WIDTH, width) - (isTablet ? 45 : 125), [width, isTablet]);

  const classes = useStyles({ activeTheme, pageMaxHeight, pageMaxWidth });

  const goToPrevPage = useCallback(() => {
    window?.READIUM?.reader?.openPagePrev();
  }, []);

  const goToNextPage = useCallback(() => {
    window?.READIUM?.reader?.openPageNext();
  }, []);

  const closeEbookReader = useCallback(() => {
    changeEbookReaderOpenAction(false);
  }, []);

  const handlePaginationChanged = useCallback(() => {
    try {
      const paginationInfo = window.READIUM.reader.getPaginationInfo();
      const spineItems = paginationInfo?.spineItems;
      const [firstOpenPage] = paginationInfo?.openPages ?? [];
      const chapter = firstOpenPage?.spineItemIndex + 1;

      if (chapter !== prevChapterRef.current?.currentPage || prevChapterRef.current?.totalPages !== spineItems.length) {
        updateEBookDataAction({ currentPage: chapter, totalPages: spineItems.length });
      }

      if (!isEBookLoaded) {
        setIsEBookLoaded(true);
        setTimelineItems(spineItems);
      }

      prevChapterRef.current = { currentPage: chapter, totalPages: spineItems.length };
    } catch (error) {
      logger.empty();
    }
  }, [isEBookLoaded]);

  const initEpubReader = useCallback((epubPath) => {
    const fontsArray = window.getFontFaces(`${EPUB_READER_FOLDER}/font-faces/`);

    window.require.config({
      waitSeconds: 0, // http://requirejs.org/docs/api.html#config-waitSeconds
      config: {
        'readium_js_viewer/ModuleConfig': {
          jsLibRoot: `${EPUB_READER_FOLDER}/scripts/zip/`,
          mathJaxUrl: `${EPUB_READER_FOLDER}/scripts/mathjax/MathJax.js`,
          annotationCSSUrl: `${EPUB_READER_FOLDER}/css/annotations.css`,
          imagePathPrefix: `${EPUB_READER_FOLDER}/`,
          fonts: fontsArray,
          canHandleDirectory: false,
          canHandleUrl: false,
          useSimpleLoader: false,
          epubLibraryPath: undefined,
          epubReadingSystemUrl: undefined,
          workerUrl: undefined,
        },
      },
    });

    window.require('readium_js_viewer/EpubReader').loadUI({
      setIsLoading,
      epub: epubPath,
      logger,
      embedded: false,
      onPaginationChange: handlePaginationChanged,
    });
  }, []);

  const loadEbookAsset = useCallback(() => {
    const { downloadUrl } = activeQueueItem;

    if (downloadUrl) {
      getMediaKey(downloadUrl)
        .then(({ key }) => {
          if (key) {
            initEpubReader(key);
            // window?.READIUM?.reader?.
          }
        })
        .catch(() => {
          resetPlayerAction();
        });
    }
  }, [activeQueueItem]);

  const waitForReadium = useCallback(() => {
    if (window?.READIUM && window?.getFontFaces) {
      loadEbookAsset();
    } else {
      setTimeout(waitForReadium, 250);
    }
  }, []);

  useEffect(() => {
    if (isEBookLoaded) {
      const items = getTOCItems();

      setTocItems(items);
      window?.READIUM?.reader?.updateSettings?.({ columnMinWidth: 350 });
    }
  }, [isEBookLoaded]);

  // init reader
  useEffect(() => {
    waitForReadium();

    // Clean up
    return () => {
      updateEBookDataAction({ totalPages: 0, currentPage: 1, skipHistoryUpdate: true });
      window.require('readium_js_viewer/EpubReader').unloadUI();
    };
  }, []);

  const handlePageClick = useCallback(() => {
    if (isTablet) {
      setIsDistractionFreeMode((current) => !current);
    }
  }, [isTablet]);

  const [addSwipeHandlers, removeSwipeHandlers] = useEpubReaderSwipes({ goToNextPage, goToPrevPage });

  useEffect(() => {
    if (isEBookLoaded) {
      const currentSettings = window?.READIUM?.reader?.viewerSettings();
      const { columnGap, syntheticSpread } = currentSettings;

      if (pageMaxWidth > 830) {
        const newColumnGap = syntheticSpread === 'single' ? columnGap / 1.5 : columnGap;
        window?.READIUM?.reader?.updateSettings?.({ syntheticSpread: 'double', columnGap: newColumnGap });
      } else {
        const newColumnGap = syntheticSpread === 'double' ? columnGap * 1.5 : columnGap;
        window?.READIUM?.reader?.updateSettings?.({ syntheticSpread: 'single', columnGap: newColumnGap });
      }
    }
  }, [isEBookLoaded, pageMaxWidth]);

  useEffect(() => {
    if (isTablet && isEBookLoaded) {
      removeSwipeHandlers();
      addSwipeHandlers();

      // add click listener for destruction mode
      const readerContainer = document.querySelector('#epub-reader-container');

      // Remove previous listeners
      readerContainer.removeEventListener('click', handlePageClick);
      window?.READIUM?.reader.removeCustomIframeEventListener('click', handlePageClick);
      // Add new listeners
      readerContainer.addEventListener('click', handlePageClick);
      window?.READIUM?.reader.addCustomIframeEventListener('click', handlePageClick);
    }
  }, [isTablet, currentPage, isEBookLoaded]);

  const renderControlPanel = useCallback(
    () => (isEBookLoaded ? <ControlPanel tocItems={tocItems} activeTheme={activeTheme} /> : null),
    [tocItems, activeTheme, isEBookLoaded],
  );

  const handleTimelineChange = useCallback(
    (newChapterNumber) => {
      const { href } = timelineItems[newChapterNumber - 1];
      const tocUrl = window?.READIUM?.reader?.tocUrl;

      window?.READIUM?.reader?.openContentUrl(href, tocUrl, undefined);
    },
    [timelineItems],
  );

  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href={CSS_READER_PATH} />
      </Helmet>
      <Fade in={!isDistractionFreeMode}>
        <Box>
          <FullScreenHeader
            height={99}
            item={activeQueueItem}
            onClose={closeEbookReader}
            nameClassName={classes.textThemeColor}
            creatorClassName={classes.textThemeColor}
            renderControlPanel={renderControlPanel}
          />
        </Box>
      </Fade>
      <Box className={classes.readerViewContainer}>
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.leftArrow)}>
            <IconButton onClick={goToPrevPage} className={classes.arrowIconBtn}>
              <ArrowBackIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.rightArrow)}>
            <IconButton onClick={goToNextPage} className={classes.arrowIconBtn}>
              <ArrowForwardIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        {isLoading && <Loader />}
        <div id="app-container" className={classes.readerView} />
        <div id="fake-navigation" className={classes.hide} />
      </Box>
      {!!timelineItems.length && (
        <EbookTimeline
          progressText={tocItems.find((item) => item.href === timelineItems[currentPage - 1].href)?.title ?? '-'}
          onChange={handleTimelineChange}
          textClassName={classes.textThemeColor}
          isDistractionFreeMode={isDistractionFreeMode}
          sliderProps={{
            ValueLabelComponent: (allProps) => (
              <ValueLabelComponent {...allProps} classes={classes} tocItems={tocItems} timelineItems={timelineItems} />
            ),
          }}
        />
      )}
    </>
  );
});

EpubReader.propTypes = {
  queue: queuePropType,
  ebookData: ebookDataPropType,
  activeIndex: PropTypes.number,
  resetPlayerAction: PropTypes.func.isRequired,
  updateEBookDataAction: PropTypes.func.isRequired,
  changeEbookReaderOpenAction: PropTypes.func.isRequired,
};

export default EpubReader;

import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';
import {
  HEADER_HEIGHT,
  MINI_PLAYER_HEIGHT,
  DEFAULT_SEARCH_INPUT_WIDTH,
  SEARCH_INPUT_MARGIN_RIGHT,
  CONTENT_WIDTH,
  CONTENT_PADDING,
  HEADER_LOGO_IMAGE_WIDTH,
} from 'utils/themes/sizes';

const getResultContainerHeight = (withMiniPlayer, headerHeight) => {
  const nonContentHeight = headerHeight + (withMiniPlayer ? MINI_PLAYER_HEIGHT : 0);

  return `calc(100% - ${nonContentHeight}px)`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginRight: ({ collapsed }) => (collapsed ? SEARCH_INPUT_MARGIN_RIGHT : 0),
    alignItems: 'center',
    justifyContent: ({ collapsed }) => (collapsed ? 'center' : 'unset'),
  },
  containerWithOpenInput: {
    marginRight: 0,
  },
  searchIcon: {
    width: 22,
    height: 22,
    marginLeft: 12,
    cursor: 'pointer',
    opacity: 1,
    transition: '0.15s',
    color: theme.palette.colors.surfaceOnColor,
  },
  closeIcon: {
    width: 18,
    height: 18,
    marginRight: 5,
    cursor: 'pointer',
    transition: '0.35s',
    color: theme.palette.colors.surfaceOnColor,
  },
  input: {
    width: 0,
    height: 34,
    transition: '0.35s',
    color: theme.palette.colors.surfaceOnColor,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.05)',
  },
  openedInput: {
    width: ({ collapsed, availableSearchWidth }) =>
      collapsed
        ? Math.min(
            availableSearchWidth || CONTENT_WIDTH - CONTENT_PADDING * 2 - HEADER_LOGO_IMAGE_WIDTH,
            DEFAULT_SEARCH_INPUT_WIDTH,
          )
        : '100%',
    borderRadius: 6,
    marginRight: `-${SEARCH_INPUT_MARGIN_RIGHT}px`,
    border: `1px solid ${theme.palette.colors.surfaceMutedColor}`,
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  resultListContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    backdropFilter: 'blur(50px)',
    backgroundColor: alpha(theme.palette.colors.surfaceColor, 0.5),
  },
  resultListContentContainer: {
    marginTop: HEADER_HEIGHT,
    height: ({ withMiniPlayer }) => getResultContainerHeight(withMiniPlayer, HEADER_HEIGHT),
    overflowY: 'auto',
    maskImage: `linear-gradient( to bottom, transparent, ${theme.palette.colors.surfaceColor} 20px, ${theme.palette.colors.surfaceColor} calc(100% - 32px), transparent), linear-gradient(${theme.palette.colors.surfaceColor}, ${theme.palette.colors.surfaceColor})`,
    maskSize: 'calc(100% - 8px) 100%, 8px 100%',
    maskPosition: '0 0, 100% 0',
    maskRepeat: 'no-repeat, no-repeat',
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      height: ({ withMiniPlayer }) => getResultContainerHeight(withMiniPlayer, 70 + 40),
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '0px 8px',
      height: ({ withMiniPlayer }) => getResultContainerHeight(withMiniPlayer, 60 + 40),
    },
  },
  resultListContent: {
    width: '100%',
    height: '100%',
    padding: 0,
    overflow: 'hidden',
  },
  errorMessage: {
    marginTop: 36,
    textAlign: 'center',
  },
}));

export default useStyles;

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getMediaKey } from 'api/media';
import logger from 'utils/logger';
import { isTouchDevice } from 'utils/screen';
import { isEbookContentType } from 'utils/assets';
import { ebookDataPropType, queuePropType } from 'utils/prop-types';
import useMediaQuery from 'hook/use-media-query';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FullScreenHeader from 'components/full-screen-components/header';
import { PubhubReader as PublizonPubhubReader } from '@publizon/pubhub-reader';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import useStyles from './pubhub-reader.styles';
import { getColorOptions } from './pubhub-reader.utils';
import ControlPanel from './components/control-panel';
import EbookTimeline from '../components/ebook-timeline';
import AnnotationModal from './components/annotation-modal';

const ENVIRONMENTS = Object.freeze({
  live: 0,
  qa: 1,
});

const PubhubReader = memo((props) => {
  const theme = useTheme();
  const isTablet = useMediaQuery('tablet');

  const {
    queue,
    ebookData: { activeTheme },
    activeIndex,
    resetPlayerAction,
    changeEbookReaderOpenAction,
    updateEBookDataAction,
  } = props;
  const isTouchableDevice = isTouchDevice();
  const classes = useStyles({ activeTheme });
  const activeQueueItem = queue[activeIndex];

  const readerRef = useRef();

  const [isDistractionFreeMode, setIsDistractionFreeMode] = useState(false);
  const [isBookmarkActive, setIsBookmarkActive] = useState(false);
  const [isReaderInstanceSaved, setIsReaderInstanceSaved] = useState(false);
  const [isEBookLoaded, setIsEBookLoaded] = useState(false);

  const toggleBookmark = useCallback(() => {
    readerRef.current.markings.toggleBookmark();
  }, []);

  const goToPrevPage = useCallback(() => {
    readerRef.current.navigation.previousPage().catch(logger.empty);
  }, []);

  const goToNextPage = useCallback(() => {
    readerRef.current.navigation.nextPage().catch(logger.empty);
  }, []);

  const handleTimelineVisibleRangeChanged = useCallback((event) => {
    const newTotalPages = event.totalNumberOfPages;
    const newCurrentPage = event.timelineVisibleStartPageIndex;

    updateEBookDataAction({ totalPages: newTotalPages, currentPage: newCurrentPage });
  }, []);

  const closeEbookReader = useCallback(() => {
    changeEbookReaderOpenAction(false);
  }, []);

  // Keyboard listener for previousPage/nextPage navigation
  const handleKeydown = useCallback((event) => {
    if (!['INPUT', 'TEXTAREA'].includes(event.nodeName)) {
      switch (event.key) {
        case 'ArrowLeft': {
          goToPrevPage();
          break;
        }
        case 'ArrowRight': {
          goToNextPage();
          break;
        }
        default:
          break;
      }
    }
  }, []);

  // Bookmark on visible page listener
  const handleVisibleMarkingsChanged = useCallback((event) => {
    setIsBookmarkActive(event.hasVisibleMarkings);
  }, []);

  // External link popup
  const handleExternalLinkClick = useCallback((event) => {
    const newWindow = window.open(event.href, '_blank');

    if (newWindow !== null) {
      newWindow.focus();
    }
  }, []);

  const handleError = useCallback((event) => {
    logger.log('PubhubReader', event.errorMessage, event.error);
    resetPlayerAction();
    setIsEBookLoaded(false);
  }, []);

  const handleClick = useCallback(() => {
    if (isTablet) {
      setIsDistractionFreeMode((current) => !current);
    }
  }, [isTablet]);

  const loadEbookAsset = useCallback(() => {
    const { downloadUrl, contentType } = activeQueueItem ?? {};

    if (isEbookContentType({ contentType }) && downloadUrl) {
      getMediaKey(downloadUrl)
        .then(({ key, env }) => {
          if (key) {
            const environment = ENVIRONMENTS[env?.toLowerCase() ?? 'qa'];

            if (environment) {
              logger.log('loadEbookAsset', `Loading ebook using ${env} environment.`);
            }

            readerRef.current.loadByOrderId(key, environment)?.catch?.(() => {
              resetPlayerAction();
            });
            setIsEBookLoaded(true);
          }
        })
        .catch(() => {
          resetPlayerAction();
          setIsEBookLoaded(false);
        });
    }
  }, [activeQueueItem]);

  // init reader
  useEffect(() => {
    const options = {
      highlightColors: getColorOptions(theme, activeTheme),
    };

    readerRef.current = new PublizonPubhubReader(options);

    readerRef.current.eventListeners.addListener('click', handleClick);
    readerRef.current.eventListeners.addListener('error', handleError);
    readerRef.current.eventListeners.addListener('keydown', handleKeydown);
    readerRef.current.eventListeners.addListener('externalLinkClick', handleExternalLinkClick);
    readerRef.current.eventListeners.addListener('visibleMarkingsChanged', handleVisibleMarkingsChanged);
    readerRef.current.eventListeners.addListener('timelineVisibleRangeChanged', handleTimelineVisibleRangeChanged);

    setIsReaderInstanceSaved(true);

    // Clean up
    return () => {
      updateEBookDataAction({ totalPages: 0, currentPage: 1, skipHistoryUpdate: true });
      readerRef.current.eventListeners.removeListener('click', handleClick);
      readerRef.current.eventListeners.removeListener('error', handleError);
      readerRef.current.eventListeners.removeListener('keydown', handleKeydown);
      readerRef.current.eventListeners.removeListener('externalLinkClick', handleExternalLinkClick);
      readerRef.current.eventListeners.removeListener('visibleMarkingsChanged', handleVisibleMarkingsChanged);
      readerRef.current.eventListeners.removeListener('timelineVisibleRangeChanged', handleTimelineVisibleRangeChanged);
      readerRef.current.unloadAll();
    };
  }, []);

  useEffect(() => {
    if (isReaderInstanceSaved) {
      const renderViewElement = document.getElementById('reader-view');

      if (renderViewElement) {
        readerRef.current
          .renderTo(renderViewElement)
          ?.catch?.((error) => logger.log('PubhubReader', 'Error while rendering reader view.', error));
        loadEbookAsset();
      }
    }
  }, [isReaderInstanceSaved]);

  const renderControlPanel = useCallback(
    () =>
      isEBookLoaded ? (
        <ControlPanel
          readerRef={readerRef}
          activeTheme={activeTheme}
          isBookmarkActive={isBookmarkActive}
          toggleBookmark={toggleBookmark}
        />
      ) : null,
    [activeTheme, toggleBookmark, isBookmarkActive, isEBookLoaded],
  );

  const handleTimelineChange = useCallback(
    (newPageNumber) => {
      readerRef?.current?.navigation?.goToPage?.(newPageNumber);
    },
    [readerRef],
  );

  return (
    <>
      {activeQueueItem && (
        <Fade in={!isDistractionFreeMode}>
          <Box>
            <FullScreenHeader
              height={99}
              item={activeQueueItem}
              onClose={closeEbookReader}
              nameClassName={classes.textThemeColor}
              creatorClassName={classes.textThemeColor}
              renderControlPanel={renderControlPanel}
            />
          </Box>
        </Fade>
      )}
      <Box className={classes.readerViewContainer}>
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.leftArrow)}>
            <IconButton onClick={goToPrevPage} className={classes.arrowIconBtn}>
              <ArrowBackIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.rightArrow)}>
            <IconButton onClick={goToNextPage} className={classes.arrowIconBtn}>
              <ArrowForwardIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        <Box id="reader-view" className={classes.readerView} />
        {isEBookLoaded && <AnnotationModal readerRef={readerRef} activeTheme={activeTheme} />}
      </Box>
      <EbookTimeline
        onChange={handleTimelineChange}
        textClassName={classes.textThemeColor}
        isDistractionFreeMode={isDistractionFreeMode}
      />
    </>
  );
});

PubhubReader.propTypes = {
  queue: queuePropType,
  ebookData: ebookDataPropType,
  activeIndex: PropTypes.number,
  resetPlayerAction: PropTypes.func.isRequired,
  updateEBookDataAction: PropTypes.func.isRequired,
  changeEbookReaderOpenAction: PropTypes.func.isRequired,
};

export default PubhubReader;
